import cloneDeep from 'lodash/cloneDeep';
import { Fragment } from 'react/jsx-runtime';
import styled from 'styled-components';
import { LabeledSwitchButton } from '../../../../core/components/buttons/LabeledSwitchButton';
import { colors } from '../../../../styles/colors';
import { PracticeSubscriptionCreationData } from '../../../../types/WebUser/InvitationDataType';
import { useSubscriptionConfiguratorDataConnect } from '../utils/useSubscriptionConfiguratorDataConnect';
import { subscriptionPriceMonth } from './AdminInvitationSubscriptionCreator';

type Props = {
  practiceData: PracticeSubscriptionCreationData;
  changePracticeData: (value: PracticeSubscriptionCreationData) => void;
};

const cycleOptions = [
  { value: 3, label: '3 Monate' },
  { value: 6, label: '6 Monate' },
  { value: 12, label: '12 Monate' },
  { value: 18, label: '18 Monate' },
  { value: 24, label: '24 Monate' },
];

export const AdminPracticeSubscriptionSubPhase = ({ practiceData, changePracticeData }: Props) => {
  const { subscriptionFinalDiscounts: subscriptionDiscounts } = useSubscriptionConfiguratorDataConnect();
  const data = practiceData.subscription.secondPhase;

  const changeDiscount = (value: number) => {
    if (!data) return;
    const newPracticeData = cloneDeep(practiceData);
    if (value === 0) {
      newPracticeData.subscription.secondPhase = {
        originalPrice: data?.originalPrice,
        price: data?.originalPrice,
      };
    } else {
      newPracticeData.subscription.secondPhase = {
        ...practiceData.subscription,
        originalPrice: data?.originalPrice,
        price: data?.originalPrice * (1 - value / 100),
        couponDetails: {
          percentage: value,
        },
      };
    }
    changePracticeData(newPracticeData);
  };

  const changeCycle = (value: string) => {
    const valueNumber = parseInt(value);
    const newOriginalPrice = subscriptionPriceMonth * valueNumber;
    const newPrice = data?.couponDetails?.percentage
      ? newOriginalPrice * (1 - data?.couponDetails.percentage / 100)
      : newOriginalPrice;
    const newPracticeData = cloneDeep(practiceData);
    newPracticeData.subscription.secondPhase = {
      ...newPracticeData.subscription,
      originalPrice: newOriginalPrice,
      price: newPrice,
      cycleDetails: {
        monthCycle: valueNumber,
      },
    };
    changePracticeData(newPracticeData);
  };

  const changeItemDisabled = (value: boolean) => {
    const newPracticeData = cloneDeep(practiceData);
    if (value) {
      delete newPracticeData.subscription.secondPhase;
      newPracticeData.subscription.cycleDetails = {
        ...newPracticeData.subscription.cycleDetails,
      };
      delete newPracticeData.subscription.cycleDetails.amountOfCycles;
      newPracticeData.subscription.couponDetails = {
        ...newPracticeData.subscription.couponDetails,
      };
    } else {
      newPracticeData.subscription.secondPhase = {
        originalPrice: subscriptionPriceMonth * 12,
        price: subscriptionPriceMonth * 12,
        cycleDetails: {
          monthCycle: 12,
        },
      };
      newPracticeData.subscription.cycleDetails = {
        ...newPracticeData.subscription.cycleDetails,
        amountOfCycles: 1,
      };
      newPracticeData.subscription.couponDetails = {
        ...newPracticeData.subscription.couponDetails,
      };
    }
    changePracticeData(newPracticeData);
  };

  return (
    <TableRow>
      <TableCell>
        {'Zweite Abo Phase'}
        {changeItemDisabled && (
          <LabeledSwitchButton
            isChecked={data ? true : false}
            setIsChecked={(value) => changeItemDisabled(!value)}
            label=""
          />
        )}
      </TableCell>
      {!data ? (
        <Fragment>
          <TableCell>{' - '}</TableCell>
          <TableCell>{' - '}</TableCell>
          <TableCell>{' - '}</TableCell>
          <TableCell>{' - '}</TableCell>
          <TableCell>{' - '}</TableCell>
          <TableCell>{' - '}</TableCell>
          <TableCell>{' - '}</TableCell>
        </Fragment>
      ) : (
        <Fragment>
          <TableCell>{data?.originalPrice} €</TableCell>
          <TableCell>
            <Select
              value={data?.couponDetails?.percentage ?? 0}
              onChange={(value) => changeDiscount(parseFloat(value.target.value))}
            >
              {subscriptionDiscounts.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </TableCell>
          <TableCell>
            <Select value={data?.cycleDetails.monthCycle} onChange={(value) => changeCycle(value.target.value)}>
              {cycleOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </TableCell>
          <TableCell>{' - '}</TableCell>
          <TableCell>{`${data.price} € (mtl. ${data.price / data.cycleDetails.monthCycle} €)`}</TableCell>
          <TableCell>{' - '}</TableCell>
          <TableCell>{' - '}</TableCell>
        </Fragment>
      )}
    </TableRow>
  );
};

const TableRow = styled.tr`
  border-bottom: 1px solid ${colors.Light};
  &:last-child {
    border-bottom: none;
  }
`;

const TableCell = styled.td`
  padding: 12px;
  color: ${colors.Dark};
`;

const Select = styled.select`
  width: 100%;
  padding: 8px;
  border: 1px solid ${colors.Light};
  border-radius: 4px;
  background: ${colors.White};
`;
