export type AdminUser = {
  id: string;
  invitationLink: string;
  firstName: string;
  lastName: string;
  email: string;
  state: 'pending' | 'created';
  roles: {
    allRoles: boolean;
    inviteNewUser: boolean; // Neue Mitarbeiter einladen
    managePractices: boolean; // Praxen verwalten
    sales: boolean; // Eigene Einladungen verwalten
    salesAll: boolean; // Alle Einladungen verwalten
    salesManager: boolean; // Angebotsoptionen verwalten
    logIntoPractice: boolean; // In Praxis einloggen
  };
};

export const adminAccessRightsArray = [
  'allRoles',
  'inviteNewUser',
  'managePractices',
  'sales',
  'salesAll',
  'salesManager',
  'logIntoPractice',
];
