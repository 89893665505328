import { Navigate, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { FullScreenContainer } from '../../../../core/components/container/FullScreenContainer';
import { NavigationHeader } from '../../../../navigation/components/NavigationHeader';
import { useMainStore } from '../../../../store/mainStore';
import { CheckoutBill } from '../../../onboarding/components/checkoutBill/CheckoutBill';
import { useUserAccessRights } from '../../utils/useUserAccessRights';
import { AdminInvitationDetails } from '../components/AdminInvitationDetails';
import { AdminInvitationSubscriptionCreator } from '../components/AdminInvitationSubscriptionCreator';
import { useInvitationConnect } from '../utils/useInvitationConnect';

export const AdminCreateSubscriptionScreen = () => {
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const { accessMail, salesAllAccess, salesAccess } = useUserAccessRights();
  const navigate = useNavigate();
  const invitationId = useParams().invitationId;
  const { invitation, loading } = useInvitationConnect(invitationId);

  const close = () => {
    navigate('/admin/sales/partner-invitations');
  };

  if (!salesAccess || (accessMail !== invitation?.hubspotData.ownerData.email && !salesAllAccess)) {
    setEventIndicator('error', 'Kein Zugriff auf diese Seite');
    return <Navigate to="/" />;
  }

  if (loading) return <div>Loading...</div>;
  if (!invitation) return <div>Invitation not found</div>;
  return (
    <FullScreenContainer>
      <Container>
        <ContentContainer>
          <AdminInvitationDetails invitation={invitation} />

          <AdminInvitationSubscriptionCreator invitation={invitation} />
          <BillContainer>
            {invitation.subscription && <CheckoutBill subscription={invitation.subscription} />}
          </BillContainer>
        </ContentContainer>
        <NavigationHeader onCloseClick={close} />
      </Container>
    </FullScreenContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 40px;
  overflow: hidden;
  align-items: center;
`;

const BillContainer = styled.div`
  width: 640px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 40px;
`;
