import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { ScreenContainer } from '../../../core/components/container/ScreenContainer';
import { Header } from '../../../navigation/components/Header';

export const AdminLayout = () => {
  return (
    <ScreenContainer>
      <Header />
      <OutletContainer className="onBoardingScreenContainer-scroll-ancestor">
        <Outlet />
      </OutletContainer>
    </ScreenContainer>
  );
};

const OutletContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  overflow-x: hidden;
`;
