import { getFunctions, httpsCallable } from 'firebase/functions';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ActionMenu } from '../../../../core/components/ActionMenu';
import { useMainStore } from '../../../../store/mainStore';
import { colors } from '../../../../styles/colors';
import { Body } from '../../../../styles/textStyles';
import { AdminUser } from '../../../../types/Admin/AdminUserType';

type Props = {
  user: AdminUser;
};

export const AdminUserLine = ({ user }: Props) => {
  const navigate = useNavigate();
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const openChangeRightsModal = () => {
    navigate('access', { state: { adminUser: user } });
  };

  const deleteUser = async () => {
    setLoadingDelete(true);
    try {
      const functions = getFunctions();
      const result = (
        await httpsCallable(
          functions,
          'deleteAdminUser',
        )({
          idToDelete: user.id,
        })
      )?.data as any;
      if (result?.status === 'error') {
        throw new Error(result?.message);
      }
      setEventIndicator('success', 'User gelöscht');
    } catch (e: any) {
      setEventIndicator('error', e.message);
    } finally {
      setLoadingDelete(false);
    }
  };

  let actions = [
    { label: 'Rechte anpassen', onClick: openChangeRightsModal },
    { label: 'User löschen', onClick: deleteUser, disabled: loadingDelete },
  ];
  return (
    <Container>
      <Row>
        <Element>{user.firstName + ' ' + user.lastName}</Element>
        <Element>{user.email}</Element>
        <Element>{user.state}</Element>
        <Roles>
          {Object.entries(user.roles)
            .filter(([_, value]) => value)
            .map(([key, _]) => (
              <Element key={key}>{key}</Element>
            ))}
        </Roles>
        <ActionMenu actions={actions} />
      </Row>
    </Container>
  );
};

const Container = styled.div``;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid ${colors.Light};
`;

const Element = styled(Body)``;

const Roles = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;
