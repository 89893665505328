import styled from 'styled-components';
import { PracticeSubscriptionCreationData } from '../../../../types/WebUser/InvitationDataType';
import { CheckoutBillDetailsElement } from './CheckoutBillDetailsElement';

type Props = {
  subscription: {
    offer?: string;
    practices: PracticeSubscriptionCreationData[];
  };
  practiceIndex?: number;
};

export const CheckoutBillDetails = ({ subscription, practiceIndex }: Props) => {
  const { brandingData, onboardingData, subscriptionData } = getPrices(subscription.practices, practiceIndex);
  const brandingElement = {
    ...brandingData,
    name: 'Branding deiner Heimübungs-App',
  };
  const onboardingElement = {
    ...onboardingData,
    name: 'Einführungs Gebühr',
  };
  const subscriptionElement = {
    ...subscriptionData,
    name: 'Physiofit Servicegebühr',
  };

  const onTimeElements = [onboardingElement];
  const noBranding = subscription.practices.every((practice) => !practice.oneTimePayments.branding);

  if (!noBranding) {
    onTimeElements.push(brandingElement);
  }

  return (
    <Container>
      <CheckoutBillDetailsElement title={'Einmalige Zahlungen'} data={onTimeElements} offer={subscription.offer} />
      <CheckoutBillDetailsElement
        title={'Wiederkehrende Zahlungen'}
        data={[subscriptionElement]}
        offer={subscription.offer}
      />
    </Container>
  );
};

const getSubscriptionDetailsText = (data: PracticeSubscriptionCreationData) => {
  const startDateString = data.startDetails.startDate;
  const startDate = startDateString ? new Date(startDateString) : new Date();
  let text = '';
  if (startDateString) {
    text += `Wird zum vereinbarten Bereitstellungsdatum am ${startDate.toLocaleDateString('de', { year: 'numeric', month: '2-digit', day: '2-digit' })} abgebucht. `;
  }
  if (data.subscription.secondPhase) {
    const firstPhaseMonthDuration =
      data.subscription.cycleDetails.monthCycle * (data.subscription.cycleDetails.amountOfCycles ?? 1);
    const monthCycle = data.subscription.secondPhase.cycleDetails.monthCycle;
    const durationText = monthCycle === 12 ? 'jährlich' : `alle ${monthCycle} Monate`;
    const priceText = `Der gezeigte Preis gilt für die ersten ${firstPhaseMonthDuration} Monate.`;
    const iterationText = `Danach beträgt die Servicegebühr ${durationText} ${data.subscription.secondPhase.price}€.`;
    text += `${priceText} ${iterationText}`;
  }
  return text;
};

const getBrandingData = (practices: PracticeSubscriptionCreationData[], practiceIndex?: number) => {
  if (practiceIndex !== undefined) {
    const brandingData = practices[practiceIndex].oneTimePayments.branding;
    if (!brandingData) return;
    return {
      originalPrice: brandingData.originalPrice,
      price: brandingData.price,
      percentage: brandingData.couponPercentage,
      elements: [
        {
          name: practices[practiceIndex].name,
          originalPrice: brandingData.originalPrice,
          price: brandingData.price,
          couponPercentage: brandingData.couponPercentage,
        },
      ],
    };
  } else {
    let result: any = practices.reduce(
      (acc, curr) => {
        const brandingData = curr?.oneTimePayments?.branding;
        if (!curr || !brandingData) return acc;
        return {
          originalPrice: acc.originalPrice + brandingData.originalPrice,
          price: acc.price + brandingData.price,
          elements: acc.elements.concat({
            name: curr.name,
            originalPrice: brandingData.originalPrice,
            price: brandingData.price,
            couponPercentage: brandingData.couponPercentage,
          }),
        };
      },
      { originalPrice: 0, price: 0, elements: [] as any[] },
    );
    result.percentage = result.price / result.originalPrice;
    return result;
  }
};

const getOnboardingData = (practices: PracticeSubscriptionCreationData[], practiceIndex?: number) => {
  if (practiceIndex !== undefined) {
    const onboardingData = practices[practiceIndex].oneTimePayments.onboardingFee;
    if (!onboardingData) return;
    return {
      originalPrice: onboardingData.originalPrice,
      price: onboardingData.price,
      percentage: onboardingData.couponPercentage,
      elements: [
        {
          name: practices[practiceIndex].name,
          originalPrice: onboardingData.originalPrice,
          price: onboardingData.price,
          couponPercentage: onboardingData.couponPercentage,
        },
      ],
    };
  } else {
    let result: any = practices.reduce(
      (acc, curr) => {
        const onboardingData = curr?.oneTimePayments?.onboardingFee;
        if (!curr || !onboardingData) return acc;
        return {
          originalPrice: acc.originalPrice + onboardingData.originalPrice,
          price: acc.price + onboardingData.price,
          elements: acc.elements.concat({
            name: curr.name,
            originalPrice: onboardingData.originalPrice,
            price: onboardingData.price,
            couponPercentage: onboardingData.couponPercentage,
          }),
        };
      },
      { originalPrice: 0, price: 0, elements: [] as any[] },
    );
    result.percentage = result.price / result.originalPrice;
    return result;
  }
};

const getSubscriptionData = (practices: PracticeSubscriptionCreationData[], practiceIndex?: number) => {
  if (practiceIndex !== undefined) {
    const subscriptionData = practices[practiceIndex].subscription;
    if (!subscriptionData) return;
    return {
      originalPrice: subscriptionData.originalPrice,
      price: subscriptionData.price,
      couponPercentage: subscriptionData.couponDetails?.percentage ?? 0,
      elements: [
        {
          name: practices[practiceIndex].name,
          originalPrice: subscriptionData.originalPrice,
          price: subscriptionData.price,
          couponPercentage: subscriptionData.couponDetails?.percentage ?? 0,
          text: getSubscriptionDetailsText(practices[practiceIndex]),
        },
      ],
    };
  } else {
    let result: any = practices.reduce(
      (acc, curr) => {
        const subscriptionData = curr.subscription;
        if (!curr || !subscriptionData) return acc;
        return {
          originalPrice: acc.originalPrice + subscriptionData.originalPrice,
          price: acc.price + subscriptionData.price,
          elements: acc.elements.concat({
            name: curr.name,
            originalPrice: subscriptionData.originalPrice,
            price: subscriptionData.price,
            couponPercentage: subscriptionData.couponDetails?.percentage ?? 0,
            text: getSubscriptionDetailsText(curr),
          }),
        };
      },
      { originalPrice: 0, price: 0, elements: [] as any[] },
    );
    result.percentage = result.price / result.originalPrice;
    return result;
  }
};

const getPrices = (practices: PracticeSubscriptionCreationData[], practiceIndex?: number) => {
  const brandingData = getBrandingData(practices, practiceIndex);
  const onboardingData = getOnboardingData(practices, practiceIndex);
  const subscriptionData = getSubscriptionData(practices, practiceIndex);
  return {
    brandingData,
    onboardingData,
    subscriptionData,
  };
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;
