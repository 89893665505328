import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Skeleton from 'react-loading-skeleton';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { InfoText } from '../../../core/components/text/InfoText';
import { NavigationHeader } from '../../../navigation/components/NavigationHeader';
import { getInvitationSubscription } from '../../../store/invitationSlice';
import { useMainStore } from '../../../store/mainStore';
import { DESKTOP_BREAKPOINT } from '../../../styles/breakpoints';
import { colors } from '../../../styles/colors';
import { CheckoutBill } from '../components/checkoutBill/CheckoutBill';
import { OnboardingCheckoutForm } from '../components/OnboardingCheckoutForm';
import { useCreateSubscription } from '../utils/useCreateSubscription';
import { useOnboardingPracticeInfo } from '../utils/useOnboardingPracticeInfo';

const stripe = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

export const OnboardingCheckoutScreen = () => {
  const navigate = useNavigate();
  const { practiceId } = useOnboardingPracticeInfo();
  const { clientSecret, loading } = useCreateSubscription();

  const subscription = useMainStore(getInvitationSubscription);
  const { paymentType, practiceIndex } = useOnboardingPracticeInfo();

  if (!practiceId) {
    return <Navigate to="../../practices-names" />;
  }

  const handleBack = () => {
    navigate('../invoice-address');
  };

  return (
    <>
      <NavigationHeader onBackClick={handleBack} />
      <OuterContainer>
        <InfoText headline={'Deine Physiofit Servicegebühr'} />
        <Container>
          <ContentContainer>
            {subscription && (
              <CheckoutBill
                subscription={subscription}
                practiceIndex={paymentType === 'same' ? undefined : practiceIndex}
              />
            )}
          </ContentContainer>
          {loading || !clientSecret ? (
            <LoadingSkeletonContainer>
              <Skeleton height={300} />
              <Skeleton height={200} />
            </LoadingSkeletonContainer>
          ) : (
            <Elements stripe={stripe} options={{ locale: 'de', appearance, clientSecret }}>
              <OnboardingCheckoutForm />
            </Elements>
          )}
        </Container>
      </OuterContainer>
      <Outlet />
    </>
  );
};

const appearance = {
  theme: 'stripe' as any,
  variables: {
    colorPrimary: colors.Primary,
    colorText: colors.Dark,
  },
};

const Container = styled.div<{ $hideProgress?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 30px;
  width: 975px;
  justify-content: ${(props) => (props.$hideProgress ? 'center' : 'flex-start')};
  background-color: 'blue';
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    max-width: 640px;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    gap: 40px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 640px;
  width: 100%;
  gap: 50px;
`;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding-bottom: 200px;
`;

const LoadingSkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  gap: 20px;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    max-width: none;
    padding-top: 0;
    max-width: 100%;
  }
`;
