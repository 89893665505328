import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { InfoText } from '../../../core/components/text/InfoText';
import { SingleTextInput } from '../../../core/components/textInput/SingleTextInput';
import {
  addPracticesToChain,
  createNewPracticesFirestore,
  setChainOnboardingFlag,
} from '../../../core/utils/editFirestore/editChain';
import { getChainPractices, getOnboardingFlags } from '../../../store/chainSlice';
import { getInvitationPracticeNames } from '../../../store/invitationSlice';
import { useMainStore } from '../../../store/mainStore';
import { OnboardingScreenContainer } from '../components/OnboardingScreenContainer';
import { useOnboardingPracticeInfo } from '../utils/useOnboardingPracticeInfo';

export const OnboardingPracticeNamesURL = 'practices-names';

export const OnboardingPracticeNamesScreen = () => {
  const navigate = useNavigate();
  const initialPractices = useMainStore(getChainPractices);
  const invitationPracticeNames = useMainStore(getInvitationPracticeNames);
  const { isMigration } = useOnboardingPracticeInfo();
  const [loading, setLoading] = useState(false);
  const [lengthErrors, setLengthErrors] = useState<{ id: string; text: string; resetTimer: NodeJS.Timeout }[]>([]);
  const { t } = useTranslation();

  const [practices, setPractices] = useState<{ name: string; id: string }[]>(
    initialPractices ??
      invitationPracticeNames?.map((e) => ({ name: e, id: uuidv4() })) ?? [{ name: '', id: uuidv4() }],
  );

  const onboardingFlags = useMainStore(getOnboardingFlags);
  const practiceFlags = onboardingFlags?.practices;

  const handleNext = async () => {
    if (practices.filter((p) => p.name === '').length > 0) return;
    setLoading(true);
    try {
      const newPractices = practices.filter((e) => !practiceFlags?.[e.id]?.completed);
      await addPracticesToChain(practices);
      await createNewPracticesFirestore(newPractices);
      await setChainOnboardingFlag('practiceNames', 'practiceNames');
      const firstPracticeId = newPractices?.[0].id;
      navigate(`../${firstPracticeId}/practice-data-info`);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  console.log(initialPractices);
  const changePracticeName = (id: string, value: string) => {
    let newValue = value;
    const newPractices = [...practices];

    if (value.length > 40) {
      const existingError = lengthErrors.find((e) => e.id === id);
      if (existingError?.resetTimer) {
        clearTimeout(existingError.resetTimer);
      }

      const resetTimer = setTimeout(() => {
        setLengthErrors((prev) => prev.filter((e) => e.id !== id));
      }, 2000);

      setLengthErrors((prev) => {
        const filtered = prev.filter((e) => e.id !== id);
        return [...filtered, { id, text: 'Maximal 40 Zeichen', resetTimer }];
      });

      newValue = value.substring(0, 40);
    } else {
      setLengthErrors((prev) => prev.filter((e) => e.id !== id));
    }
    const index = newPractices.findIndex((e) => e.id === id);
    newPractices[index] = { name: newValue, id };
    setPractices(newPractices);
  };

  const handleBack = () => {
    navigate('../basic-info');
  };

  if (isMigration) {
    return <Navigate to="/onboarding/basic-info" replace={true} />;
  }

  const hasDuplicates = practices.some((e, i) => practices.some((f, j) => e.name === f.name && i !== j));
  return (
    <OnboardingScreenContainer
      handleBack={handleBack}
      nextButton={{
        onClick: handleNext,
        loading,
        numberOfSteps: practices.length,
        currentStep: practices.filter((e) => e.name !== '').length,
        disabled: hasDuplicates,
      }}
    >
      <InfoText
        headline={'Wie heißen deine Praxis Standorte?'}
        text={['Wir haben deine Praxen bereits für dich angelegt. Bitte wähle jeweils einen passenden Namen.']}
      />
      <LocationNamesContainer>
        {practices.map((practice, index) => {
          const duplicate =
            practices?.filter((p) => p.name === practice.name).length > 1
              ? 'Bitte gib jedem Standort einen eindeutigen Namen'
              : undefined;
          return (
            <SingleTextInput
              id={index === 0 ? 'firstInput' : ''}
              key={index}
              value={practice.name}
              onChange={(value) => changePracticeName(practice.id, value)}
              placeholder={t('ONBOARDING-PRACTICE-NAMES-SCREEN-PRACTICE-NAME-PLACEHOLDER')}
              label={'Praxisname ' + invitationPracticeNames?.[index]}
              disabled={practiceFlags?.[practice.id]?.checkout}
              error={duplicate ?? lengthErrors.find((e) => e.id === practice.id)?.text}
            />
          );
        })}
      </LocationNamesContainer>
    </OnboardingScreenContainer>
  );
};

const LocationNamesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
