import { useState } from 'react';
import styled from 'styled-components';
import { MainButton } from '../../../../core/components/buttons/MainButton';
import { TextButton } from '../../../../core/components/buttons/TextButton';
import { SingleTextInput } from '../../../../core/components/textInput/SingleTextInput';
import { colors } from '../../../../styles/colors';
import { H3, Small } from '../../../../styles/textStyles';

type Props = {
  discountNames: { label: string; value: string }[];
  changeDiscounts: (discounts: { label: string; value: string }[]) => void;
};

export const AdminSubscriptionConfiguratorDiscountNames = ({ discountNames, changeDiscounts }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [inputLabel, setInputLabel] = useState('');

  const handleAddDiscount = () => {
    const newDiscount = {
      label: inputLabel,
      value: inputValue,
    };

    const updatedDiscounts = [...discountNames, newDiscount];
    changeDiscounts(updatedDiscounts);
    setInputValue('');
    setInputLabel('');
    setIsOpen(false);
  };

  const handleDeleteDiscount = (index: number) => {
    const updatedDiscounts = discountNames.filter((_, i) => i !== index);
    changeDiscounts(updatedDiscounts);
  };

  const changeInputValue = (value: string) => {
    if (value.length > 20) return;
    setInputValue(value);
  };

  const changeInputLabel = (value: string) => {
    if (value.length > 20) return;
    setInputLabel(value);
  };

  const addButtonDisabled = inputValue === '' || discountNames.some((discount) => discount.value === inputValue);

  return (
    <Container>
      <StyledH3>{'Rabattaktionen'}</StyledH3>

      <DiscountList>
        {discountNames.map((discount, index) => (
          <DiscountItem key={index}>
            <TextContainer>
              <DiscountText>{discount.label}</DiscountText>
              <DiscountCustomerText>{`'${discount.value}'`}</DiscountCustomerText>
            </TextContainer>
            <DeleteButton onClick={() => handleDeleteDiscount(index)}>×</DeleteButton>
          </DiscountItem>
        ))}
      </DiscountList>
      <DropdownContainer>
        <TextButton onClick={() => setIsOpen(!isOpen)} text="Rabattaktion hinzufügen" />
        {isOpen && (
          <DropdownContent>
            <InputGroup>
              <SingleTextInput
                label={'Kundenname'}
                placeholder={'Wird dem Kunden auf der Rechnung angezeigt'}
                value={inputValue}
                onChange={changeInputValue}
              />
              <SingleTextInput
                label={'Interner Name'}
                placeholder={'Wird zum auswählen des Rabatts verwendet'}
                value={inputLabel}
                onChange={changeInputLabel}
              />

              <MainButton disabled={addButtonDisabled} onClick={handleAddDiscount} text="Hinzufügen" />
            </InputGroup>
          </DropdownContent>
        )}
      </DropdownContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background-color: ${colors.Light};
  border-radius: 10px;
`;

const StyledH3 = styled(H3)`
  color: ${colors.Dark};
`;

const DropdownContainer = styled.div`
  position: relative;
  min-width: 400px;
`;

const DropdownContent = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid ${colors.Dark};
  border-top: none;
  border-radius: 0 0 4px 4px;
  z-index: 1;
`;

const InputGroup = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const DiscountList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const DiscountItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  background-color: ${colors.White};
  border-radius: 16px;
`;

const DiscountText = styled.span`
  color: ${colors.Dark};
`;

const DeleteButton = styled.button`
  background: none;
  border: none;
  color: ${colors.Dark};
  font-size: 20px;
  cursor: pointer;
  padding: 0 5px;

  &:hover {
    color: red;
  }
`;
const DiscountCustomerText = styled(Small)`
  color: ${colors.Medium};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;
