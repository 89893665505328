import { doc, getDoc, setDoc } from 'firebase/firestore';
import { checkVAT, countries } from 'jsvat';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoaderFunction, LoaderFunctionArgs, Navigate, useLoaderData, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { LabeledSwitchButton } from '../../../core/components/buttons/LabeledSwitchButton';
import { InfoText } from '../../../core/components/text/InfoText';
import { db } from '../../../firebaseConfig';
import { getChainPractices } from '../../../store/chainSlice';
import { useMainStore } from '../../../store/mainStore';
import { getUserMail } from '../../../store/userSlice';
import { PracticeContact } from '../../../types/Practices/PracticeContactType';
import { PracticeInvoiceData } from '../../../types/Practices/PracticeInvoiceDataType';
import { InvoiceAddressInformation } from '../components/InvoiceAddressInformation';
import { OnboardingScreenContainer } from '../components/OnboardingScreenContainer';
import { useHandleTransferInvoicePracticeData } from '../utils/useHandleTransferInvoicePracticeData';
import { useOnboardingPracticeInfo } from '../utils/useOnboardingPracticeInfo';

export const practiceInvoiceDataLoader: LoaderFunction = async ({ params }: LoaderFunctionArgs) => {
  const practiceId = params.practiceId;
  if (!practiceId) return null;
  const practiceInvoiceSnapshot = await getDoc(doc(db, 'practices/' + practiceId + '/documents/practiceInvoiceData'));
  const practiceInvoiceData = practiceInvoiceSnapshot?.data() as undefined | PracticeInvoiceData;
  const practiceContactSnapshot = await getDoc(doc(db, 'practices/' + practiceId + '/documents/practiceContact'));
  const practiceContactData = practiceContactSnapshot?.data() as undefined | PracticeContact;

  return { practiceInvoiceData, practiceContactData };
};

export const OnboardingPracticeInvoiceAddressScreen = () => {
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);

  const loaderData = useLoaderData() as null | {
    practiceInvoiceData: PracticeInvoiceData;
    practiceContactData: PracticeContact;
  };
  const practiceInvoiceData = loaderData?.practiceInvoiceData;
  const practiceContactData = loaderData?.practiceContactData;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { practiceId, practiceIndex, practiceName, paymentType, nextPracticeId, chainId } = useOnboardingPracticeInfo();

  const ownerMail = useMainStore(getUserMail);
  const practices = useMainStore(getChainPractices);

  const [currentStep, setCurrentStep] = useState(practiceInvoiceData ? 5 : 0);
  const totalSteps = 5;
  const [loading, setLoading] = useState(false);

  const { fields, setFields, toggleActive, handleToggle } = useHandleTransferInvoicePracticeData(
    practiceId ?? '',
    setCurrentStep,
    practiceInvoiceData,
  );
  const handleNext = async () => {
    if (currentStep < totalSteps || !practices || !practiceId || !chainId) return;
    const samePayment = paymentType === 'same';
    try {
      setLoading(true);
      const data: PracticeInvoiceData = {
        address: {
          name: fields.practiceName,
          street: fields.street,
          houseNumber: fields.houseNumber,
          cityCode: fields.cityCode,
          city: fields.city,
        },
        taxId: fields.taxId,
        invoiceMail: (samePayment ? ownerMail : practiceContactData?.email) ?? '',
        practiceId,
        onboardingCopy: toggleActive,
      };

      if (samePayment) {
        const promises = practices?.map(async (practice) => {
          await setDoc(doc(db, 'practices/' + practice.id + '/documents/practiceInvoiceData'), data);
        });
        await Promise.all(promises);
      } else {
        await setDoc(doc(db, 'practices/' + practiceId + '/documents/practiceInvoiceData'), data);
      }
      if (nextPracticeId && samePayment) {
        navigate(`../../${nextPracticeId}/practice-data-info`);
      } else {
        navigate('../checkout');
      }
    } catch (error) {
      setEventIndicator('error', 'Ein Fehler ist aufgetreten');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    if ((practices?.length && practiceIndex) || practices?.length === 1) {
      navigate('../practice-starter');
    } else {
      navigate('../payment-type');
    }
  };

  if (!practiceId) {
    return <Navigate to="../../practices-names" />;
  }
  const taxIdInvalid = Boolean(fields.taxId && checkVAT(fields.taxId, countries).isValid === false);

  return (
    <OnboardingScreenContainer
      handleBack={handleBack}
      nextButton={{
        loading,
        currentStep: currentStep,
        numberOfSteps: totalSteps,
        onClick: handleNext,
        disabled: taxIdInvalid,
      }}
    >
      <InfoText
        preHeadline={
          paymentType !== 'same'
            ? t('ONBOARDING-PRACTICE-PRE-HEADLINE', { practiceName })
            : t('ONBOARDING-INVOICE-PRETITLE-ALL')
        }
        headline={t('ONBOARDING-INVOICE-TITLE')}
        text={[t('ONBOARDING-INVOICE-SUBTITLE')]}
      />
      <Container>
        <LabeledSwitchButton
          label={t('ONBOARDING-PRACTICE-STARTER-PACK-SCREEN-USE-BILLING-ADDRESS')}
          isChecked={toggleActive}
          setIsChecked={handleToggle}
        />
        <InvoiceAddressInformation setCurrentStep={setCurrentStep} fields={fields} setFields={setFields} />
      </Container>
    </OnboardingScreenContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;
