import styled from 'styled-components';
import { colors, getMediumRGBA } from '../../../../styles/colors';
import { MonoText, Small } from '../../../../styles/textStyles';
import { PracticeSubscriptionCreationData } from '../../../../types/WebUser/InvitationDataType';
import { CheckoutBillConclusionElements } from './CheckoutBillConclusionElements';

type Props = {
  subscription: {
    offer?: string;
    practices: PracticeSubscriptionCreationData[];
  };
  practiceIndex?: number;
};

export const CheckoutBillConclusion = ({ subscription, practiceIndex }: Props) => {
  const { now, later } = getPrices(subscription.practices, practiceIndex);
  const nowBrutto = now?.price + now?.tax;
  const laterBrutto = later?.price + later?.tax;
  return (
    <Container>
      <StyledSmall>{'Zusammenfassung'}</StyledSmall>
      <Card>
        <CheckoutBillConclusionElements data={now} text={'Jetzt zu zahlen'} />
        {laterBrutto > 0 && (
          <>
            {nowBrutto > 0 && <Divider />}
            <CheckoutBillConclusionElements data={later} text={'Zukünftig zu zahlen'} />
            <LaterSubtitle>{'Wird zum vereinbarten Bereitstellungsdatum abgebucht.'}</LaterSubtitle>
          </>
        )}
        {subscription.offer && <OfferText>{`*${subscription.offer}`}</OfferText>}
      </Card>
    </Container>
  );
};

const getElementPrices = (element?: { originalPrice: number; price: number }) => {
  if (!element) return { originalPrice: 0, price: 0 };
  return { originalPrice: element.originalPrice, price: element.price };
};

export type PriceDetails = {
  brandingOriginalPrice?: number;
  brandingPrice?: number;
  onboardingOriginalPrice?: number;
  onboardingPrice?: number;
  subscriptionOriginalPrice?: number;
  subscriptionPrice?: number;
  price: number;
  tax: number;
};
const getPricesPracticeNow = (practice: PracticeSubscriptionCreationData): PriceDetails => {
  const brandingData = getElementPrices(practice.oneTimePayments.branding);
  const onboardingData = getElementPrices(practice.oneTimePayments.onboardingFee);
  const subscription = getElementPrices(practice.subscription);

  if (practice.startDetails.startImmediately) {
    return {
      brandingOriginalPrice: brandingData.originalPrice,
      brandingPrice: brandingData.price,
      onboardingOriginalPrice: onboardingData.originalPrice,
      onboardingPrice: onboardingData.price,
      subscriptionOriginalPrice: subscription.originalPrice,
      subscriptionPrice: subscription.price,
      price: brandingData.price + onboardingData.price + subscription.price,
      tax: getTax(brandingData.price + onboardingData.price + subscription.price),
    };
  } else {
    let result: PriceDetails = {
      price: 0,
      tax: 0,
    };

    if (!practice.oneTimePayments.branding?.payLater && practice.oneTimePayments.branding) {
      result = {
        brandingOriginalPrice: brandingData.originalPrice,
        brandingPrice: brandingData.price,
        price: result.price + brandingData.price,
        tax: result.tax + getTax(brandingData.price),
      };
    }

    if (!practice.oneTimePayments.onboardingFee.payLater) {
      result = {
        ...result,
        onboardingOriginalPrice: onboardingData.originalPrice,
        onboardingPrice: onboardingData.price,
        price: result.price + onboardingData.price,
        tax: result.tax + getTax(onboardingData.price),
      };
    }
    return result;
  }
};

const getPricesPracticeLater = (practice: PracticeSubscriptionCreationData): PriceDetails => {
  const brandingData = getElementPrices(practice.oneTimePayments.branding);
  const onboardingData = getElementPrices(practice.oneTimePayments.onboardingFee);
  const subscription = getElementPrices(practice.subscription);
  if (practice.startDetails.startImmediately) {
    return {
      price: 0,
      tax: 0,
    };
  } else {
    let result: PriceDetails = {
      price: 0,
      tax: 0,
    };

    if (practice.oneTimePayments.branding?.payLater) {
      result = {
        brandingOriginalPrice: brandingData.originalPrice,
        brandingPrice: brandingData.price,
        price: brandingData.price,
        tax: getTax(brandingData.price),
      };
    }

    if (practice.oneTimePayments.onboardingFee.payLater) {
      result = {
        ...result,
        onboardingOriginalPrice: onboardingData.originalPrice,
        onboardingPrice: onboardingData.price,
        price: result.price + onboardingData.price,
        tax: result.tax + getTax(onboardingData.price),
      };
    }
    result = {
      ...result,
      subscriptionOriginalPrice: subscription.originalPrice,
      subscriptionPrice: subscription.price,
      price: result.price + subscription.price,
      tax: result.tax + getTax(subscription.price),
    };
    return result;
  }
};

const getTax = (price: number) => {
  return price * 0.19;
};

const getBrutto = (price: number) => {
  return price + getTax(price);
};

const getPricesPractice = (practice: PracticeSubscriptionCreationData) => {
  const priceNow = getPricesPracticeNow(practice);
  const priceLater = getPricesPracticeLater(practice);
  const now = {
    ...priceNow,
    brutto: getBrutto(priceNow.price),
  };
  const later = {
    ...priceLater,
    brutto: getBrutto(priceLater.price),
  };
  return { now, later };
};

const getPrices = (practices: PracticeSubscriptionCreationData[], practiceIndex?: number) => {
  if (practiceIndex !== undefined) {
    return getPricesPractice(practices[practiceIndex]);
  } else {
    const prices = practices.map((practice) => getPricesPractice(practice));
    return prices.reduce(
      (acc, curr) => {
        if (curr.now?.brandingOriginalPrice !== undefined && curr.now?.brandingPrice !== undefined) {
          acc = {
            ...acc,
            now: {
              ...acc.now,
              brandingOriginalPrice: (acc.now?.brandingOriginalPrice ?? 0) + curr.now?.brandingOriginalPrice,
              brandingPrice: (acc.now?.brandingPrice ?? 0) + curr.now?.brandingPrice,
            },
          };
        }
        if (curr.now?.onboardingOriginalPrice !== undefined && curr.now?.onboardingPrice !== undefined) {
          acc = {
            ...acc,
            now: {
              ...acc.now,
              onboardingOriginalPrice: (acc.now?.onboardingOriginalPrice ?? 0) + curr.now?.onboardingOriginalPrice,
              onboardingPrice: (acc.now?.onboardingPrice ?? 0) + curr.now?.onboardingPrice,
            },
          };
        }
        if (curr.now?.subscriptionOriginalPrice !== undefined && curr.now?.subscriptionPrice !== undefined) {
          acc = {
            ...acc,
            now: {
              ...acc.now,
              subscriptionOriginalPrice:
                (acc.now?.subscriptionOriginalPrice ?? 0) + curr.now?.subscriptionOriginalPrice,
              subscriptionPrice: (acc.now?.subscriptionPrice ?? 0) + curr.now?.subscriptionPrice,
            },
          };
        }

        acc = {
          ...acc,
          now: {
            ...acc.now,
            price: (acc.now?.price ?? 0) + curr.now?.price,
            tax: (acc.now?.tax ?? 0) + curr.now?.tax,
          },
        };
        if (curr.later?.brandingOriginalPrice !== undefined && curr.later?.brandingPrice !== undefined) {
          acc = {
            ...acc,
            later: {
              ...acc.later,
              brandingOriginalPrice: (acc.later?.brandingOriginalPrice ?? 0) + curr.later?.brandingOriginalPrice,
              brandingPrice: (acc.later?.brandingPrice ?? 0) + curr.later?.brandingPrice,
            },
          };
        }
        if (curr.later?.onboardingOriginalPrice !== undefined && curr.later?.onboardingPrice !== undefined) {
          acc = {
            ...acc,
            later: {
              ...acc.later,
              onboardingOriginalPrice: (acc.later?.onboardingOriginalPrice ?? 0) + curr.later?.onboardingOriginalPrice,
              onboardingPrice: (acc.later?.onboardingPrice ?? 0) + curr.later?.onboardingPrice,
            },
          };
        }
        if (curr.later?.subscriptionOriginalPrice !== undefined && curr.later?.subscriptionPrice !== undefined) {
          acc = {
            ...acc,
            later: {
              ...acc.later,
              subscriptionOriginalPrice:
                (acc.later?.subscriptionOriginalPrice ?? 0) + curr.later?.subscriptionOriginalPrice,
              subscriptionPrice: (acc.later?.subscriptionPrice ?? 0) + curr.later?.subscriptionPrice,
            },
          };
        }

        acc = {
          ...acc,
          later: {
            ...acc.later,
            price: (acc.later?.price ?? 0) + curr.later?.price,
            tax: (acc.later?.tax ?? 0) + curr.later?.tax,
          },
        };
        return acc;
      },
      {} as { now: PriceDetails; later: PriceDetails },
    );
  }
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${getMediumRGBA(0.05)};
  border-radius: 10px;
  padding: 25px 20px;
  gap: 10px;
`;

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${colors.Medium};
  opacity: 0.1;
  margin: 20px 0;
`;

const LaterSubtitle = styled(Small)`
  color: ${colors.Medium};
  opacity: 0.5;
  margin-top: 10px;
`;

const StyledSmall = styled(Small)`
  color: ${colors.Medium};
  padding-left: 5px;
`;

const OfferText = styled(MonoText)`
  font-weight: 700;
  color: ${colors.Discount};
  align-self: flex-end;
`;
