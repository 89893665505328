import styled from 'styled-components';
import { colors, getMediumRGBA } from '../../../../styles/colors';
import { MonoText, Small } from '../../../../styles/textStyles';
import { CheckoutBillLine } from './CheckoutBillLine';

type Props = {
  title: string;
  data: PriceDetails[];
  offer?: string;
};

type PriceDetails = {
  name: string;
  text?: string;
  originalPrice: number;
  price: number;
  couponPercentage: number;
  elements: {
    name: string;
    originalPrice: number;
    price: number;
    couponPercentage: number;
    text?: string;
  }[];
};

export const CheckoutBillDetailsElement = ({ offer, title, data }: Props) => {
  let showDivider = false;
  return (
    <Container>
      <StyledSmall>{title}</StyledSmall>
      <Card>
        {data.map((element, index) => {
          if (element.price === undefined) return null;
          let showDividerTemp = showDivider;
          showDivider = true;
          return (
            <>
              {showDividerTemp && <Divider />}
              <CheckoutBillLine key={element.name + index} title={element.name} active />
              {element?.elements &&
                element.elements.map((subElement, subIndex) => {
                  return (
                    <CheckoutBillLine
                      key={subElement.name + subIndex}
                      title={'Standort ' + subElement.name}
                      price={subElement.price}
                      originalPrice={subElement.originalPrice}
                      text={subElement.text}
                    />
                  );
                })}
            </>
          );
        })}
        {offer && <OfferText>{`*${offer}`}</OfferText>}
      </Card>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${getMediumRGBA(0.05)};
  border-radius: 10px;
  padding: 25px 20px;
  gap: 10px;
`;

const StyledSmall = styled(Small)`
  color: ${colors.Medium};
  padding-left: 5px;
`;

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${colors.Medium};
  opacity: 0.1;
  margin: 10px 0;
`;

const OfferText = styled(MonoText)`
  font-weight: 700;
  color: ${colors.Discount};
  align-self: flex-end;
`;
