import React from 'react';
import styled from 'styled-components';
import { hexWithOpacity } from '../../../../core/utils/hexWithOpacity';
import { colors } from '../../../../styles/colors';
import { Body, H1, H2, H3, H4 } from '../../../../styles/textStyles';
import { InvitationData } from '../../../../types/WebUser/InvitationDataType';

type InfoSectionProps = {
  title: string;
  children: React.ReactNode;
};

type LabelValueProps = {
  label: string;
  value: string | number | undefined;
  fallback?: string;
};

const InfoSection: React.FC<InfoSectionProps> = ({ title, children }) => (
  <SectionContainer>
    <StyledH2>{title}</StyledH2>
    <ContentWrapper>{children}</ContentWrapper>
  </SectionContainer>
);

const LabelValue: React.FC<LabelValueProps> = ({ label, value, fallback = 'N/A' }) => (
  <StyledBody>{`${label}: ${value || fallback}`}</StyledBody>
);

const formatDate = (dateString: string | number | undefined): string => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }).format(date);
};

type Props = {
  invitation: InvitationData;
};

export const AdminInvitationDetails = ({ invitation }: Props) => {
  return (
    <Container>
      <StyledH1>Einladungs Informationen</StyledH1>
      <ProfileCard>
        <OwnerImage src={invitation.hubspotData.ownerData.profileImageUrl} alt="Owner" />
        <ProfileCardText>
          <H3>{'Deal Owner'}</H3>
          <H4>{invitation.hubspotData.ownerData.firstName + ' ' + invitation.hubspotData.ownerData.lastName}</H4>
        </ProfileCardText>
      </ProfileCard>
      <Card>
        <InfoSection title="Kontakt">
          <LabelValue label="Hubspot ID" value={`${invitation.hubspotData.hubspotContactId}`} />
          <LabelValue
            label="Name"
            value={`${invitation.hubspotData.privateInfo.firstName} ${invitation.hubspotData.privateInfo.lastName}`}
          />
          <LabelValue label="Email" value={`${invitation.hubspotData.privateInfo.email}`} />
          <LabelValue
            label="Adresse"
            value={`${invitation.hubspotData.privateInfo.city} ${invitation.hubspotData.privateInfo.cityCode}, ${invitation.hubspotData.privateInfo.addressLine}`}
          />
          <LabelValue label="Gesellschaft" value={`${invitation.hubspotData.privateInfo.company}`} />
        </InfoSection>

        <InfoSection title="Daten">
          <LabelValue label="Gespeichert" value={`${formatDate(invitation.flags.invitationSavedDate)}`} />
          <LabelValue label="Gesendet" value={`${formatDate(invitation.flags.invitationSendDate)}`} />
          <LabelValue label="Geöffnet" value={`${formatDate(invitation.flags.invitationOpenedDate)}`} />
          <LabelValue label="Gestartet" value={`${formatDate(invitation.flags.onboardingStartedDate)}`} />
          <LabelValue label="Beendet" value={`${formatDate(invitation.flags.onboardingCompletedDate)}`} />
        </InfoSection>
      </Card>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const Card = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`;

const SectionContainer = styled.div`
  padding: 24px;
  border-radius: 8px;
  flex: 1;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledH1 = styled(H1)`
  margin-bottom: 24px;
`;

const StyledH2 = styled(H2)`
  color: ${colors.Medium};
  margin-bottom: 12px;
`;

const StyledBody = styled(Body)`
  color: ${colors.Dark};
`;

const OwnerImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;

const ProfileCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  min-width: 300px;
  background-color: ${hexWithOpacity(colors.Medium, 0.1)};
  border-radius: 20px;
  align-self: flex-start;
`;

const ProfileCardText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
