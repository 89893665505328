import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../../../../firebaseConfig';
import { SubscriptionConfiguratorValues } from '../../../../types/Admin/SubscriptionConfiguratorType';

export const useSubscriptionConfiguratorDataConnect = () => {
  const [loading, setLoading] = useState(true);
  const [subscriptionConfigData, setData] = useState<SubscriptionConfiguratorValues>();

  useEffect(() => {
    const sub = onSnapshot(doc(db, 'appInfo/subscriptionConfigurator'), (snapshot) => {
      setLoading(false);
      setData(snapshot.data() as SubscriptionConfiguratorValues);
    });
    return () => sub();
  }, []);
  return {
    loading,
    subscriptionConfigData,
    specialOffers: [{ label: 'Keine', value: '' }, ...(subscriptionConfigData?.discountNames || [])],
    onboardingMaxDiscount: subscriptionConfigData?.maxDiscountOnboarding || 100,
    brandingMaxDiscount: subscriptionConfigData?.maxDiscountBranding || 100,
    brandingDiscounts: [{ label: '-', value: 0 }, ...(subscriptionConfigData?.brandingDiscounts || [])],
    onboardingFeeDiscounts: [{ label: '-', value: 0 }, ...(subscriptionConfigData?.onboardingFeeDiscounts || [])],
    subscriptionDiscounts: [{ label: '-', value: 0 }, ...(subscriptionConfigData?.subscriptionDiscounts || [])],
    subscriptionFinalDiscounts: [
      { label: '-', value: 0 },
      ...(subscriptionConfigData?.subscriptionFinalDiscounts || []),
    ],
  };
};
