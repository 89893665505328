import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { useEffect, useRef } from 'react';
import { getDateStringShort } from '../../../core/utils/dateHandler';
import { db } from '../../../firebaseConfig';
import { getChainInvitationId, getOnboardingCompleted } from '../../../store/chainSlice';
import { useMainStore } from '../../../store/mainStore';
import { InvitationData } from '../../../types/WebUser/InvitationDataType';

export const useLoadInvitationData = () => {
  const chainInvitationId = useMainStore(getChainInvitationId);
  const setInvitationData = useMainStore((state) => state.setInvitationData);
  const queryParameters = new URLSearchParams(window.location.search);
  const newInvitationId = chainInvitationId ?? queryParameters.get('id');
  const onboardingCompleted = useMainStore(getOnboardingCompleted);
  let subscription = useRef<() => void>();
  useEffect(() => {
    if (onboardingCompleted) {
      setInvitationData(undefined);
      return;
    }
    subscription.current = onSnapshot(doc(db, 'practiceInvitations/' + newInvitationId), (doc) => {
      if (doc.exists()) {
        const data = doc.data() as InvitationData;
        setInvitationData(data);
        if (data.state === 'pending' && data.type !== 'migration') {
          updateDoc(doc.ref, {
            state: 'opened',
            stateChangeDay: getDateStringShort(new Date()),
            ['flags.invitationOpenedDate']: new Date().toISOString(),
          });
        }
        if (data.migrationState === 'pending' && data.type === 'migration') {
          updateDoc(doc.ref, {
            migrationState: 'opened',
            stateChangeDay: getDateStringShort(new Date()),
            ['flags.invitationOpenedDate']: new Date().toISOString(),
          });
        }
      }
    });
    return () => subscription.current?.();
  }, [newInvitationId]);
};
