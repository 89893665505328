import styled from 'styled-components';
import { ActionMenu } from '../../../../core/components/ActionMenu';
import { FullScreenContainer } from '../../../../core/components/container/FullScreenContainer';
import { colors } from '../../../../styles/colors';
import { H1, H3, H4 } from '../../../../styles/textStyles';
import { useAllPractices } from '../../connectUtils/useAdminAllPractices';
import { AdminPracticeStatusLine } from '../components/AdminPracticeStatusLine';
import { useDownloadPackageLabels } from '../utils/useDownloadPackageLabels';
import { useDownloadPracticesCSV } from '../utils/useDownloadPracticesCSV';

export const AdminPracticesOverviewScreen = () => {
  const { downloadAllLabels, isDownloading } = useDownloadPackageLabels();
  const practices = useAllPractices();
  const { downloadAllPracticesCSV, isDownloading: isDownloadingCSV } = useDownloadPracticesCSV(practices ?? []);

  const actions = [
    {
      label: 'Download Package Labels',
      onClick: downloadAllLabels,
      disabled: isDownloading,
    },
    {
      label: 'Download Practices CSV',
      onClick: downloadAllPracticesCSV,
      disabled: !practices || isDownloadingCSV,
    },
  ];
  return (
    <FullScreenContainer>
      <Container>
        <ActionMenuContainer>
          <H3>Actions</H3>
          <ActionMenu actions={actions} />
        </ActionMenuContainer>

        <StyledH1>Praxen Overview</StyledH1>
        <Headline>
          <Date>Erstellt am</Date>
          <Name>Name</Name>
          <Date>Status</Date>
          <H4>Actions</H4>
        </Headline>
        {practices
          ?.filter((e) => !e.isMigrated)
          .map((practice) => <AdminPracticeStatusLine key={practice.id} practice={practice} />)}
        <Date>Airtable Praxen</Date>

        <Headline>
          <Date>Erstellt am</Date>
          <Name>Name</Name>
          <Date>Migrated</Date>
        </Headline>
        {practices
          ?.filter((e) => e.isMigrated)
          .map((practice) => <AdminPracticeStatusLine key={practice.id} practice={practice} />)}
      </Container>
    </FullScreenContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

const StyledH1 = styled(H1)``;

const Headline = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid ${colors.Light};
  align-items: center;
`;

const Date = styled(H4)`
  flex: 1;
  text-align: left;
`;

const Name = styled(H4)`
  flex: 2;
  text-align: left;
`;

const ActionMenuContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: 10px;
`;
