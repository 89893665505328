import { getFunctions, httpsCallable } from 'firebase/functions';
import { cloneDeep } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ButtonGroup } from '../../../../core/components/buttons/ButtonGroup';
import { RadioButtonWithLabel } from '../../../../core/components/buttons/RadioButtonWithLabel';
import { ModalContainer } from '../../../../core/components/container/ModalContainer';
import { useMainStore } from '../../../../store/mainStore';
import { colors } from '../../../../styles/colors';
import { H3 } from '../../../../styles/textStyles';
import { adminAccessRightsArray, AdminUser } from '../../../../types/Admin/AdminUserType';

export const AdminChangeUserAccessModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const adminUser = useLocation().state?.adminUser as AdminUser | undefined;
  const [accessRights, setAccessRights] = useState<AdminUser['roles'] | undefined>(adminUser?.roles);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    navigate('..');
  };

  if (!accessRights) {
    navigate('..');
  }

  const changeAccessRights = async (key: string, value: boolean) => {
    const newAccessRights = cloneDeep(accessRights);
    newAccessRights[key] = value;
    setAccessRights(newAccessRights);
  };

  const save = async () => {
    setLoading(true);
    try {
      console.log('accessRights', accessRights);
      const functions = getFunctions();
      const result = (
        await httpsCallable(
          functions,
          'changeAdminUserAccess',
        )({
          idToChange: adminUser?.id,
          roles: accessRights,
        })
      )?.data as any;
      if (result?.status === 'error') {
        throw new Error(result?.message);
      }
      setEventIndicator('success', 'Rechte geändert');
      navigate('..');
    } catch (e: any) {
      setEventIndicator('error', e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalContainer handleClose={handleClose}>
      <Container>
        <StyledH3>{'Zugangsrechte Anpassen'}</StyledH3>
        <FormContainer>
          {adminAccessRightsArray.map((e) => (
            <RadioButtonWithLabel
              key={e}
              text={e}
              value={accessRights?.[e] ?? false}
              onPress={(value) => changeAccessRights(e, value)}
            />
          ))}
        </FormContainer>
        <ButtonGroup
          textButton={{
            text: 'Schließen',
            onClick: handleClose,
            loading,
          }}
          progressButton={{
            currentStep: 0,
            numberOfSteps: 0,
            text: t('SAVE-BUTTON'),
            onClick: save,
            loading,
          }}
        />
      </Container>
    </ModalContainer>
  );
};

const StyledH3 = styled(H3)`
  color: ${colors.Dark};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
