import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { CounterButton } from '../../../core/components/buttons/CounterButton';
import { IconButton } from '../../../core/components/buttons/IconButton';
import { ModalContainer } from '../../../core/components/container/ModalContainer';
import { InfoText } from '../../../core/components/text/InfoText';
import { colors } from '../../../styles/colors';
import { H2, H4, MonoText, Small } from '../../../styles/textStyles';
import { useOnboardingPracticeInfo } from '../utils/useOnboardingPracticeInfo';

export const formatMoney = (amount: number) => {
  if (amount === 0) return '0,00 €';
  return Number(amount).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
};

const fee = -100;

const negativeToZero = (value: number) => {
  return value < 0 ? 0 : value;
};

export const CheckoutCalculatorModal = () => {
  const { paymentType, allPracticeIds } = useOnboardingPracticeInfo();
  const navigate = useNavigate();

  const startValue = paymentType === 'same' && allPracticeIds ? allPracticeIds.length * 15 : 15;
  const [amountPatients, setAmountPatients] = useState(startValue);
  const amountIncome = amountPatients * 25;

  const { t } = useTranslation();

  const handleClose = () => {
    navigate('..');
  };

  return (
    <ModalContainer handleClose={handleClose}>
      <HeaderContainer>
        <IconButton iconName="Close" onClick={handleClose} size={24} />
      </HeaderContainer>
      <Container>
        <InfoText headline={t('ONBOARDING-CHECKOUT-SCREEN-TITLE')} text={[t('ONBOARDING-CHECKOUT-SCREEN-SUBTITLE')]} />
        <CounterContainer>
          <StyledH4>{t('ONBOARDING-CHECKOUT-SCREEN-AMOUNT-PATIENTS')}</StyledH4>
          <CounterButton value={amountPatients} setValue={setAmountPatients} stepSize={5} maxValue={10000} />
        </CounterContainer>
        <HorizontalContainer>
          <RowContainer>
            <StyledH4>{t('ONBOARDING-CHECKOUT-SCREEN-MONTHLY-INCOME')}</StyledH4>
            <StyledSmall>{'(' + t('ONBOARDING-CHECKOUT-SCREEN-MONTHLY-PATIENT-INCOME') + ')'}</StyledSmall>
          </RowContainer>
          <MonoText>{formatMoney(amountIncome)}</MonoText>
        </HorizontalContainer>
        <HorizontalContainer>
          <StyledH4>{t('ONBOARDING-CHECKOUT-SCREEN-MONTHLY-FEES')}</StyledH4>
          <MonoText>{formatMoney(fee)}</MonoText>
        </HorizontalContainer>
        <Divider />
        <HorizontalContainer>
          <StyledH4>{t('ONBOARDING-CHECKOUT-SCREEN-MONTHLY-EARNINGS')}</StyledH4>
          <StyledH2>{formatMoney(negativeToZero(amountIncome + fee))}</StyledH2>
        </HorizontalContainer>
      </Container>
    </ModalContainer>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px 40px 20px;
  width: 100%;
  gap: 10px;
  max-width: 640px;
`;
const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
`;

const StyledH4 = styled(H4)`
  color: ${colors.Medium};
`;

const StyledSmall = styled(Small)`
  color: ${colors.Medium};
`;

const StyledH2 = styled(H2)`
  color: ${colors.Medium};
`;

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${colors.Light};
  margin: 20px 0;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
`;

const CounterContainer = styled(HorizontalContainer)`
  padding-top: 10px;
  padding-bottom: 20px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
