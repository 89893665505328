// Drawer.js
import styled from 'styled-components';
import { hexWithOpacity } from '../../../../core/utils/hexWithOpacity';
import { DrawerCategory } from '../../../../navigation/components/Drawer/DrawerCategory';
import { colors } from '../../../../styles/colors';
import { useUserAccessRights } from '../../utils/useUserAccessRights';

type Props = {
  isOpen: boolean;
  toggleDrawer: () => void;
};

export const AdminDrawer = ({ isOpen, toggleDrawer }: Props) => {
  const { managePracticesAccess, inviteUserAccess, salesAccess, salesManagerAccess } = useUserAccessRights();
  const categories = [
    {
      title: 'Home',
      items: [{ title: 'Übersicht', path: '/admin/home' }],
    },
    ...(salesAccess
      ? [
          {
            title: 'Sales',
            items: [
              // { title: 'Tools', path: '/admin/sales/tools' },
              { title: 'Partner Einladungen', path: '/admin/sales/partner-invitations' },
              ...(salesManagerAccess
                ? [{ title: 'Sales Einstellungen', path: '/admin/sales/subscription-settings' }]
                : []),
            ],
          },
        ]
      : []),
    ...(managePracticesAccess
      ? [
          {
            title: 'Support',
            items: [{ title: 'Praxen Verwaltung', path: '/admin/support/practices-overview' }],
          },
        ]
      : []),
    ...(inviteUserAccess
      ? [
          {
            title: 'Einstellungen',
            items: [
              { title: 'Team', path: '/admin/settings/team' },
              { title: 'Account', path: '/admin/settings/account' },
            ],
          },
        ]
      : [
          {
            title: 'Einstellungen',
            items: [{ title: 'Account', path: '/admin/settings/account' }],
          },
        ]),
  ];
  return (
    <DrawerContainer $isOpen={isOpen}>
      <ContentContainer>
        <NavMenu>
          {categories.map((category, index) => (
            <DrawerCategory
              key={category.title + index}
              headline={category.title}
              items={category.items}
              toggleDrawer={toggleDrawer}
            />
          ))}
        </NavMenu>
      </ContentContainer>
    </DrawerContainer>
  );
};

const DrawerContainer = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  top: 0;
  height: 100vh;
  padding-top: 100px;
  left: ${(props) => (props.$isOpen ? '0' : '-250px')};
  width: 250px;
  background-color: ${colors.White};
  transition: left 0.3s ease-in-out;
  overflow-x: hidden;
  box-shadow: 2px 0 5px ${hexWithOpacity(colors.Medium, 0.1)};
`;

const ContentContainer = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;

const NavMenu = styled.div`
  flex-direction: column;
  display: flex;
  gap: 10px;
  padding-bottom: 30px;
`;

// const RecruitButton = styled(CustomButton)`
//   background-color: ${colors.Medium};
//   color: ${colors.White};
//   flex: 1;
//   width: 100%;
//   padding: 10px 15px;
//   border-radius: 10px;
//   flex-wrap: nowrap;
//   margin-top: auto;
// `;
