import { doc, updateDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useState } from 'react';
import styled from 'styled-components';
import { CounterButton } from '../../../../core/components/buttons/CounterButton';
import { MainButton } from '../../../../core/components/buttons/MainButton';
import { TagSelection } from '../../../../core/components/tags/TagSelection';
import { addValueIfExists } from '../../../../core/utils/addValueIfExists';
import { db } from '../../../../firebaseConfig';
import { useMainStore } from '../../../../store/mainStore';
import { colors } from '../../../../styles/colors';
import { H1, H4, Small } from '../../../../styles/textStyles';
import { InvitationData, PracticeSubscriptionCreationData } from '../../../../types/WebUser/InvitationDataType';
import { useSubscriptionConfiguratorDataConnect } from '../utils/useSubscriptionConfiguratorDataConnect';
import { AdminPracticeSubscriptionCreator } from './AdminPracticeSubscriptionCreator';

export const brandingPackagePrice = 750;
export const onboardingFeePrice = 280;
export const subscriptionPriceMonth = 100;

export const defaultPracticeSubscriptionData: PracticeSubscriptionCreationData = {
  name: 'Praxis 1',
  practiceIndex: 0,

  oneTimePayments: {
    branding: {
      payLater: false,
      originalPrice: brandingPackagePrice,
      price: brandingPackagePrice,
      couponType: 'percent',
    },
    onboardingFee: {
      payLater: false,
      originalPrice: onboardingFeePrice,
      price: onboardingFeePrice,
      couponType: 'percent',
    },
  },
  startDetails: {
    startImmediately: true,
  },
  subscription: {
    couponDetails: {
      percentage: 0,
    },
    cycleDetails: {
      monthCycle: 12,
    },

    originalPrice: subscriptionPriceMonth * 12,
    price: subscriptionPriceMonth * 12,
  },
};

type Props = {
  invitation: InvitationData;
};

const getInitialPracticeData = (amount: number) => {
  const initialData: PracticeSubscriptionCreationData[] = [];
  for (let i = 0; i < amount; i++) {
    initialData.push({ ...defaultPracticeSubscriptionData, name: `Praxis ${i + 1}`, practiceIndex: i });
  }
  return initialData;
};

export const AdminInvitationSubscriptionCreator = ({ invitation }: Props) => {
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const { specialOffers } = useSubscriptionConfiguratorDataConnect();

  const [savedLoading, setSavedLoading] = useState<boolean>(false);
  const [sendLoading, setSendLoading] = useState<boolean>(false);

  const [practiceDataArray, setPracticeDataArray] = useState<PracticeSubscriptionCreationData[]>(
    invitation.subscription?.practices ?? getInitialPracticeData(invitation.hubspotData.amountPracticesHubspot ?? 1),
  );
  const [offer, setOffer] = useState<string>(invitation.subscription?.offer ?? '');
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const practiceAmountChange = (value: number) => {
    if (value < 1) return;
    if (value > practiceDataArray.length) {
      setPracticeDataArray([
        ...practiceDataArray,
        {
          ...defaultPracticeSubscriptionData,
          name: `Praxis ${practiceDataArray.length + 1}`,
          practiceIndex: practiceDataArray.length,
        },
      ]);
    } else {
      setPracticeDataArray(practiceDataArray.slice(0, value));
    }
    if (value - 1 < selectedIndex) {
      setSelectedIndex(0);
    }
  };

  const changePracticeData = (value: PracticeSubscriptionCreationData) => {
    let newPracticeData = [...practiceDataArray];
    newPracticeData[selectedIndex] = value;
    setPracticeDataArray(newPracticeData);
  };

  const saveData = async () => {
    setSavedLoading(true);
    try {
      await updateDoc(doc(db, 'practiceInvitations/' + invitation.id), {
        subscription: { practices: practiceDataArray, ...addValueIfExists('offer', offer) },
        ['flags.invitationSavedDate']: new Date().toISOString(),
      });
      setEventIndicator('success', 'Daten gespeichert');
    } catch (e: any) {
      setEventIndicator('error', e.message);
    } finally {
      setSavedLoading(false);
    }
  };

  const sendData = async () => {
    if (confirm('Bist du sicher, dass du die Einladung senden möchtest?')) {
      setSendLoading(true);
      try {
        checkInvitation(invitation);
        const functions = getFunctions();
        const call = httpsCallable(functions, 'sendPortalInvitation');
        const result = (await call({ invitationId: invitation.id })) as any;
        console.log('result', result);
        if (result.data.status === 'error') {
          throw new Error(result.data.message);
        } else {
          setEventIndicator('success', 'Einladung gesendet. Glückwunsch zu deinem Praxis Abschluss! 🥳');
        }
      } catch (e: any) {
        console.log('error', JSON.stringify(e.message));
        setEventIndicator('error', JSON.stringify(e.message));
        return;
      } finally {
        setSendLoading(false);
      }
    }
  };

  const changeOffer = (value: string) => {
    setOffer(value);
  };

  return (
    <Container>
      <H1>Zahlungsdetails</H1>
      <CounterContainer>
        <SettingsLable>Angebot</SettingsLable>
        <OfferSelection value={offer} onChange={(e) => changeOffer(e.target.value)}>
          {specialOffers.map((e, i) => (
            <option key={'offer' + i} value={e.value}>
              {e.label}
            </option>
          ))}
        </OfferSelection>
      </CounterContainer>
      <CounterContainer>
        <SettingsLable>Praxis Anzahl</SettingsLable>
        <CounterButton setValue={practiceAmountChange} minValue={1} maxValue={10} value={practiceDataArray.length} />
        <TagSelection
          atLeastOne
          tags={practiceDataArray?.map((e, i) => ({ text: e.name, value: i }))}
          setSelected={(value) => setSelectedIndex(value[0] as number)}
          type="singleSelect"
          selected={[selectedIndex]}
        />
      </CounterContainer>

      <AdminPracticeSubscriptionCreator
        practiceData={practiceDataArray[selectedIndex]}
        changePracticeData={changePracticeData}
        schulungsterminDate={invitation.hubspotData?.schulungsterminDate}
      />
      <ButtonContainer>
        <MainButton text="Speichern" onClick={saveData} loading={savedLoading} />
        <ButtonSubtitle>
          {'Zuletzt gespeichert ' + (new Date(invitation.flags.invitationSavedDate).toLocaleString() ?? ' - ')}
        </ButtonSubtitle>
        <MainButton
          text="Senden"
          onClick={sendData}
          disabled={!invitation.flags.invitationSavedDate}
          loading={sendLoading}
        />
        <ButtonSubtitle>
          {'Zuletzt gesendet ' + (new Date(invitation.flags.invitationSendDate).toLocaleString() ?? ' - ')}
        </ButtonSubtitle>
      </ButtonContainer>
    </Container>
  );
};

const checkInvitation = (invitation: InvitationData) => {
  if (!invitation.hubspotData.chainName) {
    throw new Error('Kein Hubspot Kettenname vorhanden');
  }
  if (!invitation.hubspotData.hubspotContactId) {
    throw new Error('Kein Hubspot Kontakt hinterlegt');
  }
  if (!invitation.hubspotData.hubspotDealId) {
    throw new Error('Kein Hubspot Deal hinterlegt');
  }
  if (!invitation.hubspotData.ownerData.id) {
    throw new Error('Kein Hubspot Besitzer hinterlegt');
  }
  if (!invitation.subscription) {
    throw new Error('Keine Subscription hinterlegt');
  }
  if (!invitation.hubspotData.privateInfo) {
    throw new Error('Keine Kunden Informationen hinterlegt (z.B. Mail)');
  }
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const CounterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

const OfferSelection = styled.select`
  min-width: 200px;
  align-self: flex-start;
  padding: 8px;
  border: 1px solid ${colors.Light};
  border-radius: 4px;
  background: ${colors.White};
`;

const SettingsLable = styled(H4)`
  min-width: 100px;
  color: ${colors.Dark};
`;

const ButtonSubtitle = styled(Small)`
  color: ${colors.Dark};
  text-align: center;
`;
