import { saveAs } from 'file-saver';
import { doc, getDoc } from 'firebase/firestore';
import JSZip from 'jszip';
import { useState } from 'react';
import { db } from '../../../../firebaseConfig';
import { useMainStore } from '../../../../store/mainStore';
import { PracticePackageInfo } from '../../../../types/Practices/PracticePackageInfoType';
import { Practice } from '../../../../types/Practices/PracticeType';
import { useAllPractices } from '../../connectUtils/useAdminAllPractices';

const downloadLabelData = async (practices: Practice[] | undefined) => {
  if (!practices) return;
  const zip = new JSZip();
  const zipFolder = zip.folder('labels');
  const zipFolderQR = zip.folder('qrCodes');
  try {
    for (const practice of practices) {
      if (!practice) continue;
      const qrCodeUrl = practice?.practiceLinkInfo?.qrCodeURL;
      const ownerId = practice.ownerId;
      if (!qrCodeUrl || !ownerId) continue;
      const starterData = (
        await getDoc(doc(db, 'practices/' + practice.id + '/documents/practicePackageInfo'))
      ).data() as PracticePackageInfo;
      if (!starterData?.starterPackage?.labelUrl || starterData?.starterPackage?.sendInfoMailDate) continue;
      const responseQR = await fetch(qrCodeUrl);
      const blobQR = await responseQR.blob();
      const responseLabel = await fetch(starterData.starterPackage.labelUrl);
      const blobLabel = await responseLabel.blob();
      const fileName = `${practice.name.replace(/[^a-zA-Z0-9]/g, '_')}_label.pdf`;
      const fileNameQR = `${practice.name.replace(/[^a-zA-Z0-9]/g, '_')}_qrCode.png`;
      zipFolder?.file(fileName, blobLabel);
      zipFolderQR?.file(fileNameQR, blobQR);
    }

    const content = await zip.generateAsync({ type: 'blob' });
    saveAs(content, 'practice_labels.zip');
  } catch (error) {
    console.error('Error downloading labels:', error);
    throw new Error('Failed to download labels');
  }
};

export const useDownloadPackageLabels = () => {
  const eventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const practices = useAllPractices();
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadAllLabels = async () => {
    setIsDownloading(true);
    try {
      await downloadLabelData(practices);
    } catch (error) {
      eventIndicator('error', 'Failed to download labels');
    } finally {
      eventIndicator('success', 'Downloaded Labels');
      setIsDownloading(false);
    }
  };
  return { downloadAllLabels, isDownloading };
};
