import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import posthog from 'posthog-js';
import { useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import styled from 'styled-components';
import { ProgressButton } from '../../../core/components/buttons/ProgressButton';
import { getIsAddNewPractice } from '../../../store/chainSlice';
import { useMainStore } from '../../../store/mainStore';
import { DESKTOP_BREAKPOINT } from '../../../styles/breakpoints';
import { PracticeInvoiceData } from '../../../types/Practices/PracticeInvoiceDataType';
import { useOnboardingPracticeInfo } from '../utils/useOnboardingPracticeInfo';
import { OnboardingBillContractButton } from './OnboardingBillContractButton';

export const OnboardingCheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const currentDomain = window.location.origin;
  const isAddNewPractice = useMainStore(getIsAddNewPractice);
  const { practiceId } = useOnboardingPracticeInfo();
  const [confirmedContract, setConfirmedContract] = useState(false);

  const [paymentDataComplete, setPaymentDataComplete] = useState(false);
  const [elementsReady, setElementsReady] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const invoiceData = (useLoaderData() as { practiceInvoiceData: PracticeInvoiceData }).practiceInvoiceData;
  const invoiceAddress = invoiceData.address;

  const handleSubmit = async () => {
    try {
      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      setIsLoading(true);

      let result: { error: StripeError };

      result = await stripe.confirmSetup({
        // `Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          // Optional email for the receipt (if applicable)
          payment_method_data: {
            billing_details: {
              email: invoiceData?.invoiceMail,
              name: invoiceAddress?.name,
              address: {
                city: invoiceAddress?.city,
                country: 'DE', // Specify the country code
                line1: invoiceAddress?.street,
                line2: invoiceAddress?.houseNumber,
                postal_code: invoiceAddress?.cityCode,
              },
            },
          },
          return_url:
            currentDomain + `/${isAddNewPractice ? 'new-practice' : 'onboarding'}/${practiceId}/payment-status`,
        },
      });

      posthog.capture('onboarding_step_checkout_completed');

      if (result.error) {
        console.log(JSON.stringify(result));
        // Show error to your customer
        setMessage(result.error.message || 'An unexpected error occurred.');
      } else {
      }

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setMessage('An unexpected error occurred.');
    }
  };

  const currentStep = paymentDataComplete ? 1 : 0;
  return (
    <CheckoutContainer>
      <PaymentElement
        onReady={() => setElementsReady(true)}
        onChange={(event) => {
          setPaymentDataComplete(event.complete);
        }}
        options={{
          layout: {
            type: 'accordion',
            defaultCollapsed: false,
            radios: false,
            spacedAccordionItems: false,
          },
          fields: {
            billingDetails: {
              address: {
                city: 'never',
                country: 'never',
                line1: 'never',
                line2: 'never',
                postalCode: 'never',
              },
              email: 'never',
              name: 'never',
            },
          },
          business: { name: 'Digital Training and Therapy GmbH' },
        }}
      />
      {message && <div id="payment-message">{message}</div>}
      {elementsReady && (
        <>
          <OnboardingBillContractButton
            confirmedContract={confirmedContract}
            setConfirmedContract={setConfirmedContract}
          />
          <StyledProgressButton
            onClick={handleSubmit}
            text="Zahlung Abschließen"
            currentStep={currentStep}
            numberOfSteps={1}
            loading={isLoading}
            disabled={!paymentDataComplete || isLoading}
          />
        </>
      )}
    </CheckoutContainer>
  );
};

const CheckoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  gap: 20px;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    max-width: none;
    padding-top: 0;
    max-width: 100%;
  }
`;

const StyledProgressButton = styled(ProgressButton)`
  width: 100%;
`;
