import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import { ButtonsAccordionComponent } from '../../../core/components/ButtonsAccordionComponent';
import { FullScreenContainer } from '../../../core/components/container/FullScreenContainer';
import { H1 } from '../../../styles/textStyles';

export const AdminSettingsAccountScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleOpenPasswordChangeModel = () => {
    navigate('change-password');
  };
  return (
    <FullScreenContainer>
      <Container>
        <StyledH1>Account Einstellungen</StyledH1>
        <ButtonsAccordionComponent
          title={'********'}
          items={[
            {
              title: t('CHANGE-PASSWORD-BUTTON'),
              onClick: handleOpenPasswordChangeModel,
            },
          ]}
        />
      </Container>
      <Outlet />
    </FullScreenContainer>
  );
};

const StyledH1 = styled(H1)``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;
