import { collection, getDocs } from 'firebase/firestore';
import { useState } from 'react';
import { LoaderFunction, Navigate, useLoaderData, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ActionMenu } from '../../../../core/components/ActionMenu';
import { LoadingSpinner } from '../../../../core/components/loading/LoadingSpinner';
import { TagSelection } from '../../../../core/components/tags/TagSelection';
import { hexWithOpacity } from '../../../../core/utils/hexWithOpacity';
import { db } from '../../../../firebaseConfig';
import { useMainStore } from '../../../../store/mainStore';
import { colors } from '../../../../styles/colors';
import { Body, H1 } from '../../../../styles/textStyles';
import { InvitationData } from '../../../../types/WebUser/InvitationDataType';
import { MainScreenContainer } from '../../../overview/components/MainScreenComponent';
import { useUserAccessRights } from '../../utils/useUserAccessRights';

export const adminPartnerInvitationLoader: LoaderFunction = async () => {
  const invitationsSnapshot = await getDocs(collection(db, 'practiceInvitations'));
  const invitations = invitationsSnapshot.docs
    .map((doc) => doc.data() as InvitationData)
    .sort((a, b) => (a.creationTime ?? 0) - (b.creationTime ?? 0));
  return invitations;
};

export const AdminPartnerInvitationScreen = () => {
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const { accessMail, salesAllAccess, salesAccess } = useUserAccessRights();
  const [stateFilter, setStateFilter] = useState<string>();
  const invitations = useLoaderData() as InvitationData[];
  const navigate = useNavigate();
  const options = [
    { text: 'Nicht verschickt', value: 'creation' },
    { text: 'Ausstehend', value: 'pending' },
    { text: 'Geöffnet', value: 'opened' },
    { text: 'Gestartet', value: 'started' },
    { text: 'Abgeschlossen', value: 'completed' },
  ];

  let filteredInvitations = invitations;
  if (!salesAllAccess) {
    filteredInvitations = invitations.filter((invitation) => invitation.hubspotData.ownerData.email === accessMail);
  }
  if (stateFilter) {
    filteredInvitations = invitations.filter((invitation) => invitation.state === stateFilter);
  }

  const openSubscriptionEditor = (practiceId: string) => {
    navigate(`/admin/sales/create-subscription/${practiceId}`);
  };

  if (!salesAccess) {
    setEventIndicator('error', 'Kein Zugriff auf diese Seite');
    return <Navigate to="/" />;
  }

  return (
    <MainScreenContainer>
      <Container>
        <StyledH1>Sales Tools</StyledH1>
        {!invitations ? (
          <LoadingSpinner color={colors.Primary} />
        ) : (
          <>
            <TagSelection
              selected={[stateFilter]}
              tags={options}
              setSelected={(value) => {
                setStateFilter(value?.[0] as string);
              }}
              type={'singleSelect'}
            />
            <Table>
              <TableHeader>
                <HeaderCell>Date</HeaderCell>
                <HeaderCell>State</HeaderCell>
                <HeaderCell>Chain Name</HeaderCell>
                <HeaderCell>Email</HeaderCell>
                <HeaderCell>Owner</HeaderCell>
                <HeaderCell>Actions</HeaderCell>
              </TableHeader>
              <TableBody>
                {filteredInvitations.map((invitation) => {
                  const openFirestoreInvitation = () => {
                    const env = import.meta.env.VITE_ENV;
                    window.open(
                      `https://console.firebase.google.com/u/0/project/io-physiofit-${env}/firestore/databases/-default-/data/~2FpracticeInvitations~2F${invitation.id}`,
                      '_blank',
                    );
                  };
                  const actions = [
                    {
                      label: 'Einladung Firestore',
                      onClick: openFirestoreInvitation,
                    },
                    { label: 'Abo Konditionen', onClick: () => openSubscriptionEditor(invitation.id as string) },
                  ];
                  return (
                    <TableRow key={invitation.id} $completed={invitation.state === 'completed'}>
                      <TableCell>
                        {new Date(Number(invitation.creationTime)).toLocaleDateString('de', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                        })}
                      </TableCell>
                      <TableCell>{invitation.state ?? 'Not set'}</TableCell>
                      <TableCell>{invitation.hubspotData.chainName ?? 'Not set'}</TableCell>
                      <TableCell>{invitation.hubspotData.privateInfo.email ?? 'Not set'}</TableCell>
                      <TableCell>
                        {invitation.hubspotData.ownerData.firstName + ' ' + invitation.hubspotData.ownerData.lastName}
                      </TableCell>
                      <TableCell>
                        <ActionMenu actions={actions} color={colors.White} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </>
        )}
      </Container>
    </MainScreenContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 200px;
`;

const StyledH1 = styled(H1)`
  margin-bottom: 20px;
`;

const Table = styled.div`
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
`;

const TableHeader = styled.div`
  display: table-header-group;
  background-color: ${colors.Light};
  border-radius: 10px;
`;

const TableBody = styled.div`
  display: table-row-group;
`;

const TableRow = styled.div<{ $completed: boolean }>`
  display: table-row;
  background-color: ${(props) =>
    props.$completed ? hexWithOpacity(colors.Primary, 0.6) : hexWithOpacity(colors.LightRed, 0.6)};
`;

const HeaderCell = styled(Body)`
  display: table-cell;
  padding: 10px;
  font-weight: bold;
  color: ${colors.Dark};
`;

const TableCell = styled(Body)`
  display: table-cell;
  padding: 10px;
  color: ${colors.White};
`;
