import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect } from 'react';
import { db } from '../../../firebaseConfig';
import { useMainStore } from '../../../store/mainStore';
import { getFirebaseUserId, getIsMitarbeiter } from '../../../store/userSlice';
import { AdminUser } from '../../../types/Admin/AdminUserType';
import { PracticeUser } from '../../../types/WebUser/PracticeUserType';

export const useUserConnect = () => {
  const setUserData = useMainStore((state) => state.setUserData);
  const setAdminUserData = useMainStore((state) => state.setAdminUserData);
  const userId = useMainStore(getFirebaseUserId);
  const isMitarbeiter = useMainStore(getIsMitarbeiter);
  useEffect(() => {
    if (!userId) return;
    if (isMitarbeiter) {
      const subscription = onSnapshot(doc(db, 'adminUsers/' + userId), (snapshot) => {
        if (snapshot?.data()) {
          setAdminUserData(snapshot.data() as AdminUser);
        }
      });
      return () => subscription();
    } else {
      const subscription = onSnapshot(doc(db, 'practiceUsers/' + userId), (snapshot) => {
        if (snapshot?.data()) {
          setUserData(snapshot.data() as PracticeUser);
        }
      });
      return () => subscription();
    }
  }, [setUserData, userId, isMitarbeiter]);
};
