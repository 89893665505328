import { captureException } from '@sentry/react';
import { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';
import { ErrorOverlay } from './ErrorOverlay';

export const SentryRouteErrorFallback = () => {
  const routeError = useRouteError();

  useEffect(() => {
    captureException(routeError, { level: 'fatal' });
  }, [routeError]);

  console.log('SentryRouteErrorFallback', routeError);

  return <ErrorOverlay type={'Error'} />;
};
