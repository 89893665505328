import styled from 'styled-components';
import { RadioButton } from '../../../core/components/buttons/RadioButton';
import { Body } from '../../../styles/textStyles';

type Props = {
  value: boolean;
  text: string;
  onPress: (newValue: boolean) => void;
};

export const RadioButtonWithLabel = ({ value, text, onPress }: Props) => {
  return (
    <CheckboxContainer>
      <RadioButton checked={value} onPress={() => onPress(!value)} />
      <StyledBody>{text}</StyledBody>
    </CheckboxContainer>
  );
};

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const StyledBody = styled(Body)``;
