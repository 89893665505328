import styled from 'styled-components';
import { hexWithOpacity } from '../../../../core/utils/hexWithOpacity';
import { DESKTOP_BREAKPOINT } from '../../../../styles/breakpoints';
import { colors } from '../../../../styles/colors';
import { Body, H4, MonoText, Small } from '../../../../styles/textStyles';
import { formatMoney } from '../CheckoutCalculatorModal';

type Props = {
  active?: boolean;
  originalPrice?: number;
  price?: number;
  title: string;
  subtitle?: string;
  text?: string;
};

export const CheckoutBillLine = ({ active, originalPrice, price, title, subtitle, text }: Props) => {
  const hasDiscount = price !== undefined && originalPrice !== undefined && originalPrice > price;
  const percentageDiscount = hasDiscount ? Math.floor(((originalPrice - price) / originalPrice) * 100) : 0;

  const Title = !active ? TitleBody : TitleH4;
  return (
    <Container>
      <LineContainer>
        <LeftContainer $active={active ?? false}>
          <Title $active={active ?? false}>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </LeftContainer>
        <RightContainer>
          {price !== undefined && <StyledMono>{formatMoney(price)}</StyledMono>}
          {hasDiscount && (
            <CouponContainer>
              <OriginalPrice>{formatMoney(originalPrice)}</OriginalPrice>
              <DiscountText>{percentageDiscount + '% Rabatt'}</DiscountText>
            </CouponContainer>
          )}
        </RightContainer>
      </LineContainer>
      {text && <StyledSmall>{text}</StyledSmall>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
`;
const LineContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    flex-wrap: wrap;
  }
`;

const LeftContainer = styled.div<{ $active: boolean }>`
  opacity: ${(props) => (props.$active ? 1 : 0.65)};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  flex: 1;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    flex: 1;
    min-width: 100px;
  }
`;

const CouponContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const DiscountText = styled(MonoText)`
  font-weight: 700;
  color: ${colors.Discount};
  white-space: nowrap;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    display: none;
  }
`;

const OriginalPrice = styled(MonoText)`
  font-weight: 700;
  color: ${colors.Medium};
  text-decoration: line-through;
  opacity: 0.5;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    display: none;
  }
`;

const StyledMono = styled(MonoText)`
  font-weight: 700;
  color: ${colors.Medium};
`;

const TitleH4 = styled(H4)<{ $active: boolean }>`
  color: ${(props) => (props.$active ? colors.Dark : colors.Medium)};
`;

const TitleBody = styled(Body)<{ $active: boolean }>`
  color: ${(props) => (props.$active ? colors.Dark : colors.Medium)};
`;

const Subtitle = styled(Small)`
  color: ${colors.Medium};
`;

const StyledSmall = styled(Small)`
  text-align: left;
  color: ${hexWithOpacity(colors.Medium, 0.8)};
  margin: 15px 0px;
  opacity: 0.65;
`;
