import { Fragment } from 'react/jsx-runtime';
import styled from 'styled-components';
import { LabeledSwitchButton } from '../../../../core/components/buttons/LabeledSwitchButton';
import { colors } from '../../../../styles/colors';

type Props = {
  data?: {
    originalPrice: number;
    couponType: 'percent' | 'absolute';
    couponPercentage?: number;
    price: number;
    payLater: boolean;
  };
  startImmediately: boolean;
  practiceIndex: number;
  discounts: { value: number; label: string }[];
  name: string;
  changeDiscountType: (value: 'percent' | 'absolute') => void;
  changeDiscount: (value: number) => void;
  changePrice: (value: string) => void;
  changeItemDisabled?: (value: boolean) => void;
  changePayLater: (value: boolean) => void;
};

export const AdminPracticeSubscriptionOneTime = ({
  name,
  data,
  changeDiscountType,
  changeDiscount,
  changePrice,
  discounts,
  practiceIndex,
  changeItemDisabled,
  startImmediately,
  changePayLater,
}: Props) => {
  return (
    <TableRow key={name + practiceIndex}>
      <TableCell>
        <NameContainer>
          {name}
          {changeItemDisabled && (
            <LabeledSwitchButton
              isChecked={data ? true : false}
              setIsChecked={(value) => changeItemDisabled(!value)}
              label=""
            />
          )}
        </NameContainer>
      </TableCell>
      {data ? (
        <Fragment>
          <TableCell>{data.originalPrice} €</TableCell>
          <TableCell>
            <Select
              value={data.couponType}
              onChange={(value) => changeDiscountType(value.target.value as 'percent' | 'absolute')}
            >
              <option value={'percent'}>%</option>
              <option value={'absolute'}>€</option>
            </Select>
          </TableCell>
          <TableCell>
            {data.couponType === 'percent' ? (
              <Select
                value={data?.couponPercentage ?? 0}
                onChange={(value) => changeDiscount(parseFloat(value.target.value))}
              >
                {discounts.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            ) : (
              <TextInput value={data.originalPrice - data.price} onChange={(e) => changePrice(e.target.value)} />
            )}
          </TableCell>
          <TableCell>{data.price + ' €'}</TableCell>
          <TableCell>
            {startImmediately ? (
              ' - '
            ) : (
              <Checkbox
                type="checkbox"
                checked={data.payLater ?? false}
                onChange={(event) => changePayLater(event.target.checked)}
              />
            )}
          </TableCell>
        </Fragment>
      ) : (
        Array.from({ length: 5 }).map((_, index) => <TableCell key={index}> - </TableCell>)
      )}
    </TableRow>
  );
};

const TableRow = styled.tr`
  height: 68px;
`;

const TableCell = styled.td`
  padding: 12px;
  color: ${colors.Dark};
`;

const TextInput = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid ${colors.Light};
  border-radius: 4px;
`;

const Select = styled.select`
  width: 100%;
  padding: 8px;
  border: 1px solid ${colors.Light};
  border-radius: 4px;
  background: ${colors.White};
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const Checkbox = styled.input`
  width: 32px;
  height: 32px;
  padding: 8px;
  border: 1px solid ${colors.Light};
  border-radius: 4px;
  border-color: ${colors.Medium};
  background: ${colors.White};
`;
