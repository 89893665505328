import { useState } from 'react';
import styled from 'styled-components';
import { GenericSvg } from '../../../../assets/svgs/GenericSVG';
import { CustomButton } from '../../../../core/components/buttons/CustomButton';
import { DESKTOP_BREAKPOINT } from '../../../../styles/breakpoints';
import { colors } from '../../../../styles/colors';
import { H4 } from '../../../../styles/textStyles';
import { PracticeSubscriptionCreationData } from '../../../../types/WebUser/InvitationDataType';
import { CheckoutBillConclusion } from './CheckoutBillConclusion';
import { CheckoutBillDetails } from './CheckoutBillDetails';

type Props = {
  subscription: {
    offer?: string;
    practices: PracticeSubscriptionCreationData[];
  };
  practiceIndex?: number;
};
export const CheckoutBill = ({ subscription, practiceIndex }: Props) => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <Container>
      <CheckoutBillConclusion subscription={subscription} practiceIndex={practiceIndex} />
      <DetailsButton onClick={() => setShowDetails(!showDetails)}>
        <StyledH4>{'Zahlungsdetails anzeigen'}</StyledH4>
        <RotatingIconButton name="ArrowDown" $isRotated={showDetails} size={32} primaryColor={colors.Medium} />
      </DetailsButton>
      <Details $display={showDetails}>
        <CheckoutBillDetails subscription={subscription} practiceIndex={practiceIndex} />
      </Details>
      <Divider />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
const DetailsButton = styled(CustomButton)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 0px 5px;
`;

const RotatingIconButton = styled(GenericSvg)<{ $isRotated: boolean }>`
  transform: rotate(${(props) => (props.$isRotated ? '180deg' : '0deg')});
  transition: transform 0.3s ease;
`;

const Details = styled.div<{ $display: boolean }>`
  display: 'flex';
  gap: 20px;
  margin-top: 20px;
  display: ${(props) => (props.$display ? 'flex' : 'none')};
`;

const StyledH4 = styled(H4)`
  color: ${colors.Medium};
`;

const Divider = styled.div`
  background-color: ${colors.Medium};
  opacity: 0.1;
  height: 2px;
  width: 100%;
  margin-top: 10px;
  display: none;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    display: block;
  }
`;
