import { doc, setDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';
import { MainButton } from '../../../../core/components/buttons/MainButton';
import { FullScreenContainer } from '../../../../core/components/container/FullScreenContainer';
import { InfoText } from '../../../../core/components/text/InfoText';
import { db } from '../../../../firebaseConfig';
import { useMainStore } from '../../../../store/mainStore';
import { DiscountArray, SubscriptionConfiguratorValues } from '../../../../types/Admin/SubscriptionConfiguratorType';
import { useUserAccessRights } from '../../utils/useUserAccessRights';
import { AdminSubscriptionConfiguratorDiscountNames } from '../components/AdminSubscriptionConfiguratorDiscountNames';
import { AdminSubscriptionConfiguratorInput } from '../components/AdminSubscriptionConfiguratorInput';
import { useSubscriptionConfiguratorDataConnect } from '../utils/useSubscriptionConfiguratorDataConnect';

export const AdminSalesManagerScreen = () => {
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const { salesManagerAccess } = useUserAccessRights();
  const { loading: loadingConfigData, subscriptionConfigData } = useSubscriptionConfiguratorDataConnect();
  const [newValues, setNewValues] = useState<SubscriptionConfiguratorValues | undefined>(subscriptionConfigData);
  const [saveLoading, setSafeLoading] = useState(false);
  useEffect(() => {
    setNewValues(subscriptionConfigData);
  }, [subscriptionConfigData]);

  const save = async () => {
    if (!newValues) return;
    setSafeLoading(true);
    try {
      setDoc(doc(db, 'appInfo/subscriptionConfigurator'), newValues);
      setEventIndicator('success', 'Einstellungen gespeichert');
    } catch (e: any) {
      setEventIndicator('error', e.message);
    } finally {
      setSafeLoading(false);
    }
  };

  if (!salesManagerAccess) {
    return <Navigate to="/" />;
  }

  if (loadingConfigData) return <div>Loading...</div>;
  return (
    <FullScreenContainer>
      <Container>
        <InfoText
          headline={'Subscription Settings'}
          text={[
            'Aktuell ist es noch nicht möglich die Basis Preise und die Abo-Phasenlängen (Abbuchungszeitraum) zu ändern. Dazu bitte Fabian kontaktieren.',
            'Nach Änderungen bitte unten "Speichern" klicken.',
          ]}
        />
        <AdminSubscriptionConfiguratorDiscountNames
          discountNames={newValues?.discountNames ?? []}
          changeDiscounts={(discounts: { label: string; value: string }[]) =>
            setNewValues({ ...newValues, discountNames: discounts })
          }
        />
        <AdminSubscriptionConfiguratorInput
          hasLimit={true}
          title="Branding Discounts"
          discounts={newValues?.brandingDiscounts}
          changeDiscounts={(discounts: DiscountArray) => {
            setNewValues({ ...newValues, brandingDiscounts: discounts });
          }}
          limit={newValues?.maxDiscountBranding}
          changeLimit={(limit: number) => setNewValues({ ...newValues, maxDiscountBranding: limit })}
        />
        <AdminSubscriptionConfiguratorInput
          hasLimit={true}
          title="Onboarding Fee Discounts"
          discounts={newValues?.onboardingFeeDiscounts}
          changeDiscounts={(discounts: DiscountArray) => {
            setNewValues({ ...newValues, onboardingFeeDiscounts: discounts });
          }}
          limit={newValues?.maxDiscountOnboarding}
          changeLimit={(limit: number) => setNewValues({ ...newValues, maxDiscountOnboarding: limit })}
        />
        <AdminSubscriptionConfiguratorInput
          title="Subscription Discounts"
          discounts={newValues?.subscriptionDiscounts}
          changeDiscounts={(discounts: DiscountArray) => {
            setNewValues({ ...newValues, subscriptionDiscounts: discounts });
          }}
        />
        <AdminSubscriptionConfiguratorInput
          title="Subscription Second Phase Discounts"
          discounts={newValues?.subscriptionFinalDiscounts}
          changeDiscounts={(discounts: DiscountArray) => {
            setNewValues({ ...newValues, subscriptionFinalDiscounts: discounts });
          }}
        />
      </Container>
      <FloatingButtonContainer>
        <MainButton onClick={save} loading={saveLoading} text="Speichern" />
      </FloatingButtonContainer>
    </FullScreenContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

const FloatingButtonContainer = styled.div`
  position: fixed;
  bottom: 20px;
`;
