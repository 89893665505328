import { PriceDetails } from './CheckoutBillConclusion';
import { CheckoutBillLine } from './CheckoutBillLine';

type Props = {
  data: PriceDetails;
  text: string;
};

export const CheckoutBillConclusionElements = ({ data, text }: Props) => {
  const brutto = data?.price + data?.tax;
  return (
    <>
      {brutto > 0 && (
        <>
          {data.brandingPrice !== undefined && (
            <CheckoutBillLine
              title={'Branding für deine Heimübungs-App'}
              price={data.brandingPrice}
              originalPrice={data.brandingOriginalPrice}
            />
          )}
          {data.onboardingPrice !== undefined && (
            <CheckoutBillLine
              title={'Einführungsgebühr'}
              price={data.onboardingPrice}
              originalPrice={data.onboardingOriginalPrice}
            />
          )}
          {data.subscriptionPrice !== undefined && (
            <CheckoutBillLine
              title={'Servicegebühr'}
              price={data.subscriptionPrice}
              originalPrice={data.subscriptionOriginalPrice}
            />
          )}
          <CheckoutBillLine title={'Mehrwertsteuer'} price={data?.tax} />
          <CheckoutBillLine title={text} active price={brutto} />
        </>
      )}
    </>
  );
};
