import { useState } from 'react';
import styled from 'styled-components';
import { MainButton } from '../../../../core/components/buttons/MainButton';
import { TextButton } from '../../../../core/components/buttons/TextButton';
import { SingleTextInput } from '../../../../core/components/textInput/SingleTextInput';
import { colors } from '../../../../styles/colors';
import { H3 } from '../../../../styles/textStyles';
import { DiscountArray } from '../../../../types/Admin/SubscriptionConfiguratorType';

type Props = {
  title: string;
  hasLimit?: boolean;
  limit?: number;
  discounts?: DiscountArray;
  changeDiscounts: (discounts: DiscountArray) => void;
  changeLimit?: (limit: number) => void;
};

export const AdminSubscriptionConfiguratorInput = ({
  title,
  changeDiscounts,
  hasLimit,
  limit,
  changeLimit,
  discounts = [],
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleAddDiscount = () => {
    const newDiscount = {
      label: inputValue + ' %',
      value: Number(inputValue),
    };

    const updatedDiscounts = [...discounts, newDiscount];
    changeDiscounts(updatedDiscounts);
    setInputValue('');
    setIsOpen(false);
  };

  const handleDeleteDiscount = (index: number) => {
    const updatedDiscounts = discounts.filter((_, i) => i !== index);
    changeDiscounts(updatedDiscounts);
  };

  const formatText = (value: string) => {
    let newValue = value.replace(/^0+/, '');
    if (Number(newValue) > 100) {
      newValue = '100';
    }
    return newValue;
  };
  const changeInputValue = (value: string) => {
    setInputValue(formatText(value));
  };
  const handleChangeLimit = (value: string) => {
    const newValue = formatText(value);
    changeLimit?.(Number(newValue));
  };

  const addButtonDisabled = inputValue === '' || discounts.some((discount) => discount.value === Number(inputValue));

  return (
    <Container>
      <StyledH3>{title}</StyledH3>
      {hasLimit && (
        <SingleTextInput
          label="Max Discount Prozent"
          value={limit !== undefined ? String(limit) : '100'}
          onChange={handleChangeLimit}
        />
      )}
      <DiscountList>
        {discounts
          .sort((a, b) => a.value - b.value)
          .map((discount, index) => (
            <DiscountItem key={index}>
              <DiscountText>{discount.label}</DiscountText>
              <DeleteButton onClick={() => handleDeleteDiscount(index)}>×</DeleteButton>
            </DiscountItem>
          ))}
      </DiscountList>
      <DropdownContainer>
        <TextButton onClick={() => setIsOpen(!isOpen)} text="Rabattoption hinzufügen" />
        {isOpen && (
          <DropdownContent>
            <InputGroup>
              <StyledInput
                type="text"
                placeholder="Wert"
                value={inputValue}
                onChange={(e) => changeInputValue(e.target.value)}
              />
              <MainButton disabled={addButtonDisabled} onClick={handleAddDiscount} text="Hinzufügen" />
            </InputGroup>
          </DropdownContent>
        )}
      </DropdownContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background-color: ${colors.Light};
  border-radius: 10px;
`;

const StyledH3 = styled(H3)`
  color: ${colors.Dark};
`;

const DropdownContainer = styled.div`
  position: relative;
  width: 300px;
`;

const DropdownContent = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid ${colors.Dark};
  border-top: none;
  border-radius: 0 0 4px 4px;
  z-index: 1;
`;

const InputGroup = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledInput = styled.input`
  padding: 8px;
  border: 1px solid ${colors.Dark};
  border-radius: 4px;
`;

const DiscountList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const DiscountItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  background-color: ${colors.White};
  border-radius: 16px;
`;

const DiscountText = styled.span`
  color: ${colors.Dark};
`;

const DeleteButton = styled.button`
  background: none;
  border: none;
  color: ${colors.Dark};
  font-size: 20px;
  cursor: pointer;
  padding: 0 5px;

  &:hover {
    color: red;
  }
`;
