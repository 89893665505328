import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { hexWithOpacity } from '../../../core/utils/hexWithOpacity';
import { getChainPractices } from '../../../store/chainSlice';
import { useMainStore } from '../../../store/mainStore';
import { colors } from '../../../styles/colors';
import { Body, H3 } from '../../../styles/textStyles';

export const ManagePracticesComponent = () => {
  const practices = useMainStore(getChainPractices);
  const { t } = useTranslation();
  // const addLocation = () => {
  //   navigate('/new-practice/practices-names');
  // };
  return (
    <Container>
      <StyledH3>{t('MANAGE-PRACTICES-TITLE')}</StyledH3>
      <StyledBody>{t('MANAGE-PRACTICES-SUBTITLE')}</StyledBody>
      {practices?.map((practice) => (
        <PracticeContainer key={practice.id}>
          <Body>{practice.name}</Body>
        </PracticeContainer>
      ))}
      {/* <ButtonContainer>
        <ProgressButton text={t('ADD-LOCATIONS-BUTTON')} onClick={addLocation} />
      </ButtonContainer> */}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StyledH3 = styled(H3)`
  color: ${colors.Medium};
`;

const StyledBody = styled(Body)`
  color: ${colors.Medium};
`;

const PracticeContainer = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid ${hexWithOpacity(colors.Medium, 0.2)};
`;
