import { doc, onSnapshot } from 'firebase/firestore';

import { useEffect, useState } from 'react';
import { db } from '../../../../firebaseConfig';
import { InvitationData } from '../../../../types/WebUser/InvitationDataType';

export const useInvitationConnect = (invitationId?: string) => {
  const [invitation, setInvitation] = useState<InvitationData>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (invitationId) {
      const unsubscribe = onSnapshot(doc(db, 'practiceInvitations', invitationId), (doc) => {
        if (doc.exists()) {
          setInvitation(doc.data() as InvitationData);
        } else {
          setError('Invitation not found');
        }
        setLoading(false);
      });

      return () => {
        unsubscribe();
      };
    }
  }, [invitationId]);

  return { invitation, loading, error };
};
