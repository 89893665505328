import cloneDeep from 'lodash/cloneDeep';
import { useMainStore } from '../../../../store/mainStore';
import { PracticeSubscriptionCreationData } from '../../../../types/WebUser/InvitationDataType';
import { useSubscriptionConfiguratorDataConnect } from '../utils/useSubscriptionConfiguratorDataConnect';
import { onboardingFeePrice } from './AdminInvitationSubscriptionCreator';
import { calculateCouponPercentage, validateNumberInput } from './AdminPracticeSubscriptionCreator';
import { AdminPracticeSubscriptionOneTime } from './AdminPracticeSubscriptionOneTime';

type Props = {
  practiceData: PracticeSubscriptionCreationData;
  changePracticeData: (value: PracticeSubscriptionCreationData) => void;
};

export const AdminPracticeSubscriptionOnboarding = ({ practiceData, changePracticeData }: Props) => {
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const { onboardingFeeDiscounts, onboardingMaxDiscount } = useSubscriptionConfiguratorDataConnect();
  const onboarding = practiceData.oneTimePayments.onboardingFee;

  const changeDiscount = (value: number) => {
    const newPracticeData = cloneDeep(practiceData);
    if (value === 0) {
      newPracticeData.oneTimePayments.onboardingFee = {
        originalPrice: onboardingFeePrice,
        price: onboardingFeePrice,
        couponType: onboarding.couponType ?? 'percent',
      };
    } else {
      newPracticeData.oneTimePayments.onboardingFee = {
        originalPrice: onboardingFeePrice,
        price: onboardingFeePrice * (1 - value / 100),
        couponPercentage: value,
        couponType: onboarding.couponType ?? 'percent',
      };
    }
    changePracticeData(newPracticeData);
  };

  const changeDiscountType = (value: 'percent' | 'absolute') => {
    const newPracticeData = cloneDeep(practiceData);
    if (value === 'percent') {
      newPracticeData.oneTimePayments.onboardingFee = {
        ...newPracticeData.oneTimePayments.onboardingFee,
        couponType: 'percent',
        couponPercentage: 0,
        price: onboardingFeePrice,
      };
    } else {
      newPracticeData.oneTimePayments.onboardingFee = {
        ...newPracticeData.oneTimePayments.onboardingFee,
        couponType: 'absolute',
        couponPercentage: 0,
        price: onboardingFeePrice,
      };
    }
    changePracticeData(newPracticeData);
  };

  const changePayLater = (value: boolean) => {
    const newPracticeData = cloneDeep(practiceData);
    newPracticeData.oneTimePayments.onboardingFee = {
      ...newPracticeData.oneTimePayments.onboardingFee,
      payLater: value,
    };
    changePracticeData(newPracticeData);
  };

  const changePrice = (value: string) => {
    const newPracticeData = cloneDeep(practiceData);
    const newPrice = validateNumberInput(value);
    const newPriceNumber = onboardingFeePrice - Number(newPrice);
    const minPrice = onboardingFeePrice * (1 - onboardingMaxDiscount / 100);
    const maxDiscount = (onboardingFeePrice * onboardingMaxDiscount) / 100;
    if (newPriceNumber < minPrice) {
      setEventIndicator('error', `Der Rabatt kann nicht mehr als ${onboardingMaxDiscount}% (${maxDiscount}€) betragen`);
      return;
    }
    const couponPercentage = calculateCouponPercentage(onboardingFeePrice, newPriceNumber);
    if (!newPracticeData.oneTimePayments.onboardingFee) return;
    newPracticeData.oneTimePayments.onboardingFee = {
      ...newPracticeData.oneTimePayments.onboardingFee,
      price: newPriceNumber,
      couponPercentage: couponPercentage,
    };
    changePracticeData(newPracticeData);
  };

  return (
    <AdminPracticeSubscriptionOneTime
      name={'Einführungsgebühr'}
      data={onboarding}
      changeDiscountType={changeDiscountType}
      changeDiscount={changeDiscount}
      changePrice={changePrice}
      discounts={onboardingFeeDiscounts}
      practiceIndex={practiceData.practiceIndex}
      startImmediately={practiceData.startDetails.startImmediately}
      changePayLater={changePayLater}
    />
  );
};
