import cloneDeep from 'lodash/cloneDeep';
import { useMainStore } from '../../../../store/mainStore';
import { PracticeSubscriptionCreationData } from '../../../../types/WebUser/InvitationDataType';
import { useSubscriptionConfiguratorDataConnect } from '../utils/useSubscriptionConfiguratorDataConnect';
import { brandingPackagePrice, defaultPracticeSubscriptionData } from './AdminInvitationSubscriptionCreator';
import { calculateCouponPercentage, validateNumberInput } from './AdminPracticeSubscriptionCreator';
import { AdminPracticeSubscriptionOneTime } from './AdminPracticeSubscriptionOneTime';

type Props = {
  practiceData: PracticeSubscriptionCreationData;
  changePracticeData: (value: PracticeSubscriptionCreationData) => void;
};

export const AdminPracticeSubscriptionBranding = ({ practiceData, changePracticeData }: Props) => {
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const { brandingDiscounts, brandingMaxDiscount } = useSubscriptionConfiguratorDataConnect();
  const branding = practiceData.oneTimePayments.branding;

  const changeBrandingDiscount = (value: number) => {
    const newPracticeData = cloneDeep(practiceData);
    const newPrice = brandingPackagePrice * (1 - value / 100);
    if (!newPracticeData.oneTimePayments.branding) return;
    if (value === 0) {
      newPracticeData.oneTimePayments.branding = {
        originalPrice: brandingPackagePrice,
        price: brandingPackagePrice,
        couponType: newPracticeData.oneTimePayments.branding.couponType ?? 'percent',
      };
    } else {
      newPracticeData.oneTimePayments.branding = {
        originalPrice: brandingPackagePrice,
        price: newPrice,
        couponPercentage: value,
        couponType: newPracticeData.oneTimePayments.branding.couponType ?? 'percent',
      };
    }
    changePracticeData(newPracticeData);
  };

  const changeBrandingDiscountType = (value: 'percent' | 'absolute') => {
    const newPracticeData = cloneDeep(practiceData);
    if (!newPracticeData.oneTimePayments.branding) return;
    if (value === 'percent') {
      newPracticeData.oneTimePayments.branding = {
        ...newPracticeData.oneTimePayments.branding,
        couponType: 'percent',
        couponPercentage: 0,
        price: brandingPackagePrice,
      };
    } else {
      newPracticeData.oneTimePayments.branding = {
        ...newPracticeData.oneTimePayments.branding,
        couponType: 'absolute',
        couponPercentage: 0,
        price: brandingPackagePrice,
      };
    }
    changePracticeData(newPracticeData);
  };

  const changeBrandingPrice = (value: string) => {
    const newPracticeData = cloneDeep(practiceData);
    const newPrice = validateNumberInput(value);
    let newPriceNumber = brandingPackagePrice - Number(newPrice);
    const minPrice = brandingPackagePrice * (1 - brandingMaxDiscount / 100);
    const maxDiscount = (brandingPackagePrice * brandingMaxDiscount) / 100;
    if (newPriceNumber < minPrice) {
      setEventIndicator('error', `Der Rabatt kann nicht mehr als ${brandingMaxDiscount}% (${maxDiscount}€) betragen`);
      return;
    }
    const couponPercentage = calculateCouponPercentage(brandingPackagePrice, newPriceNumber);
    if (!newPracticeData.oneTimePayments.branding) return;
    newPracticeData.oneTimePayments.branding = {
      ...newPracticeData.oneTimePayments.branding,
      price: newPriceNumber,
      couponPercentage: couponPercentage,
    };
    changePracticeData(newPracticeData);
  };

  const removeBranding = (value: boolean) => {
    const newPracticeData = cloneDeep(practiceData);
    if (value) {
      delete newPracticeData.oneTimePayments.branding;
    } else {
      newPracticeData.oneTimePayments.branding = defaultPracticeSubscriptionData.oneTimePayments.branding;
    }

    changePracticeData(newPracticeData);
  };

  const changeBrandingPayLater = (value: boolean) => {
    const newPracticeData = cloneDeep(practiceData);
    newPracticeData.oneTimePayments.branding = {
      ...newPracticeData.oneTimePayments.branding,
      payLater: value,
    };
    changePracticeData(newPracticeData);
  };

  return (
    <AdminPracticeSubscriptionOneTime
      name={'Branding'}
      data={branding}
      changeDiscountType={changeBrandingDiscountType}
      changeDiscount={changeBrandingDiscount}
      changePrice={changeBrandingPrice}
      discounts={brandingDiscounts}
      practiceIndex={practiceData.practiceIndex}
      changeItemDisabled={removeBranding}
      startImmediately={practiceData.startDetails.startImmediately}
      changePayLater={changeBrandingPayLater}
    />
  );
};
